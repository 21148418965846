import { defineStore } from 'pinia';

interface Window {
    grecaptcha: any;
    onRecaptchaLoad: () => void;
}

declare const window: Window;

export const useRecaptchaStore = defineStore('recaptcha', {
    state: () => ({
        widgetId: null as number | null,
        recaptchaResponse: null as string | null
    }),
    actions: {
        initRecaptcha(siteKey: string) {
            const container = document.createElement('div');
            container.id = 'recaptcha-container';
            document.body.appendChild(container);

            try {
                this.widgetId = window.grecaptcha.render('recaptcha-container', {
                    sitekey: siteKey,
                    callback: (response: string) => {
                        this.recaptchaResponse = response;
                    },
                    'error-callback': () => {
                        this.recaptchaResponse = null;
                    }
                });
            } catch (error) {
                console.error('Error initializing reCAPTCHA:', error);
                this.recaptchaResponse = null;
            }
        },
        resetCaptcha() {
            if (this.widgetId !== null) {
                window.grecaptcha.reset(this.widgetId);
                this.recaptchaResponse = null;
            }
        },
        loadRecaptchaScript() {
            if (!window.grecaptcha) {
                const script = document.createElement('script');
                script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit`;
                script.async = true;
                script.defer = true;

                window.onRecaptchaLoad = () => {
                    this.initRecaptcha('6LcpKngqAAAAAFvCBTSS_vhqOyIxXW7_MqDEp_s3');
                };
                document.head.appendChild(script);
            } else {
                this.initRecaptcha('6LcpKngqAAAAAFvCBTSS_vhqOyIxXW7_MqDEp_s3');
            }
        }
    }
});