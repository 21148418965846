import { createRouter, createWebHistory } from 'vue-router'
import AppLayout from '@/layout/AppLayout.vue'
import { useAuthStore } from '@/modules/auth/stores/auth.store';
import { authModuleRoute } from '@/modules/auth/auth.routes'
import { mapModuleRoute } from '@/modules/map/map.routes';
import { homeModuleRoute } from '@/modules/home/home.routes';
import { electionModuleRoute } from '@/modules/election/election.routes'
import { pollingStationModuleRoute } from '@/modules/polling_station/polling-center.routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: AppLayout,
      children: [
        homeModuleRoute,
        electionModuleRoute,
        pollingStationModuleRoute,
        {
          path: 'login-index',
          name: 'auth.login.index',
          component: () => import('@/modules/auth/views/LoginIndex.vue'),
        },
      ],
    },
    authModuleRoute,
    mapModuleRoute
  ]
});


router.beforeEach(async (to) => {
  const publicPages = ['/auth/login'];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();

  if (authRequired && authStore.isLoggedInExpired) {
    authStore.returnUrl = to.fullPath;
    authStore.logout();
  }
});

export default router
