import type AccessTokenDTO from '../dtos/access-token.dto'
import type LoginDTO from '../dtos/login.dto'
import type LoginResponseDTO from '../dtos/login-response.dto'
import type AccessToken from '../models/access-token.interface'
import type { LoginResponse } from '../models/response.interface'
import type { Login } from '../models/auth.model'
import { mapAppUserFromAppUserDTO } from '@/modules/app-user/mappers/app-user.mapper'

export function mapLoginResponseFromLoginResponseDTO(dto: LoginResponseDTO): LoginResponse {
  return {
    user: mapAppUserFromAppUserDTO(dto.user),
    accessToken: mapAccessTokenFromAccessTokenDTO(dto.access_token)
  } as LoginResponse
}

export function mapAccessTokenFromAccessTokenDTO(dto: AccessTokenDTO): AccessToken {
  return {
    token: dto.access_token,
    type: dto.token_type,
    expiresIn: dto.expires_in
  } as AccessToken
}

export function mapLoginToLoginDTO(dto: Login): LoginDTO {
  return {
    pin: dto.code,
  } as LoginDTO
}
