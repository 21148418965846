import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import type AppUser from '../models/app-user.model'

import * as AppUserLocalStorageConstants from '../constants/app-user-local-storage-constants'
import { UserType } from '../enums/user-type.enum'
import UserApiService from '../services/user-api.service'

const userApiService = new UserApiService();

const appUserSecretKey = import.meta.env.VITE_APP_USER_SECRET_KEY;

export const useAppUserStore = defineStore('userStore', () => {
  const encryptedAppUser = localStorage.getItem(AppUserLocalStorageConstants.APP_USER);
  const decryptedAppUser = encryptedAppUser ? parseAccessToken(encryptedAppUser) as AppUser : null;

  const mAppUser = ref<AppUser | null>(decryptedAppUser);

  const setAppUser = (appUser: AppUser) => {
    console.log('set_app_user');
    localStorage.setItem(
      AppUserLocalStorageConstants.APP_USER,
      stringifyAccessToken(appUser)
    );
  }

  const isAppUserEmpty = computed<boolean>(() => mAppUser.value != null);

  const isAppUserSuperAdmin = computed(() => {
    if (!isAppUserEmpty.value) {
      return false;
    }
    return mAppUser.value?.userType === UserType.SUPER_ADMIN
  });

  const isAppUserAdmin = computed(() => {
    if (!isAppUserEmpty.value) {
      return false;
    }
    return mAppUser.value?.userType === UserType.ADMIN || mAppUser.value?.userType === UserType.SUPER_ADMIN
  })

  const isAppUserRegulator = computed(() => {
    if (!isAppUserEmpty.value) {
      return false;
    }
    return mAppUser.value?.userType === UserType.REGULATOR
  });

  const isAppUserCompany = computed(() => {
    if (!isAppUserEmpty.value) {
      return false;
    }
    return mAppUser.value?.userType === UserType.COMPANY
  });

  const updateFmcToken = async (token: string) => {
    const appUser = decryptedAppUser
    if (appUser) {
      const userType = appUser.userType
      const regulatorCompanyId = 0
      const employeeId = appUser.id;

      if (regulatorCompanyId) {
        const result = await userApiService.updateFmcToken(userType, regulatorCompanyId, employeeId, token)

        result.fold(
          (value) => console.log('fmc_token updated for: ', value),
          (error) => console.log('fmc_token error: ', error)
        )
      }
    }
  }

  const removeAppUser = () => localStorage.removeItem(AppUserLocalStorageConstants.APP_USER)

  function stringifyAccessToken(accessToken: AppUser) {
    return JSON.stringify(accessToken);
  }

  function parseAccessToken(jsonStr: string | null) {
    return jsonStr && jsonStr.length > 0 ? JSON.parse(jsonStr) : null;
  }

  return {
    appUser: mAppUser,
    setAppUser,
    isAppUserEmpty,
    isAppUserSuperAdmin,
    isAppUserAdmin,
    isAppUserRegulator,
    isAppUserCompany,
    updateFmcToken,
    removeAppUser
  }
})
