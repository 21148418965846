<script setup lang="ts">
import { onMounted } from 'vue';
import { useFirebaseStore } from '@/modules/firebase/stores/firebase.store';

const firebaseStore = useFirebaseStore()

onMounted(() => {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      // useFirebaseStore().setToken();
      firebaseStore.receiveMessage();
    } else {
      console.log('Permission not granted for notifications.');
    }
  });
});
</script>
<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<style scoped>
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
