<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import 'leaflet/dist/leaflet.css'
import '@/assets/styles/map/styles.css';

import { LayerLevel } from '@/modules/map/enums/layer-level.enum'

import { useDataStore } from '../../data/stores/data.store'
import { useElectionStore } from '../../election/stores/election-map.store'
import { useVoterStore } from '../../voter/stores/voter.store'
import { useMapUiStore } from '../../map/stores/ui.store'
import { usePresidentialMapStore } from '../stores/presidential-map.store'
import { useParliamentaryMapStore } from '../stores/parliamentary-map.store'


import ElectionSummaryLite from '@/modules/election/components/ElectionSummaryLite.vue'

import MapHeader from '../components/MapHeader.vue'

const mapPresidential = ref<HTMLElement | null>(null)
const mapParliamentary = ref<HTMLElement | null>(null)

const dataStore = useDataStore()
const electionStore = useElectionStore()
const voterStore = useVoterStore()
const mapUiStore = useMapUiStore()
const presidentialStore = usePresidentialMapStore()
const parliamentaryStore = useParliamentaryMapStore()

const showRegionNewButton = computed(() => presidentialStore.currentLevel !== LayerLevel.REGION_SIXTEEN)
const showRegionOldButton = computed(() => presidentialStore.currentLevel !== LayerLevel.REGION_TEN)

const showParliamentaryRegionNewButton = computed(() => parliamentaryStore.currentLevel !== LayerLevel.REGION_SIXTEEN)
const showParliamentaryRegionOldButton = computed(() => parliamentaryStore.currentLevel !== LayerLevel.REGION_TEN)


const showParliamentaryConstituencyButton = computed(
  () =>
    parliamentaryStore.currentLevel !== LayerLevel.CONSTITUENCY &&
    parliamentaryStore.currentLevel !== LayerLevel.REGION_SIXTEEN_CONSTITUENCY
)

function resetStore() {
  presidentialStore.resetStore()
  electionStore.resetStore()
  voterStore.resetStore()
  mapUiStore.resetStore()
}


onMounted(async () => {
  await Promise.all([
    dataStore.getShapeFiles(),
    dataStore.getGroupedData(),
    electionStore.getPresidentialElections(),
    electionStore.getParliamentaryElections(),
    voterStore.getVoters()
  ]);
  if (mapPresidential.value) {
    presidentialStore.initializeMap(mapPresidential.value)
    presidentialStore.initializeLayers()
  }

  if (mapParliamentary.value) {
    parliamentaryStore.initializeMap(mapParliamentary.value)
    parliamentaryStore.initializeLayers()
  }

})

</script>

<template>
  <div class="parent">

    <div class="presidential-container">
      <MapHeader title="PRESIDENTIAL RESULTS MAP" />

      <div class="presidential">
        <div class="map-statistics">
          <ElectionSummaryLite :year="electionStore.selectedPresidentialElection.year"
            :summary="electionStore.presidentialElectionResult" />
        </div>
        <div class="map-content">
          <div class="map-controls">
            <Dropdown v-model="electionStore.selectedPresidentialElection"
              :options="electionStore.presidentialElections" optionLabel="year" placeholder="Select Year" checkmark
              :highlightOnSelect="true" />
            <MultiSelect v-if="presidentialStore.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY"
              v-model="presidentialStore.selectedSixteenRegions" :options="dataStore.sixteenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialStore.selectedSixteenRegions ? presidentialStore.selectedSixteenRegions.length : 0
                    }}</b>
                  item{{
                    (presidentialStore.selectedSixteenRegions ? presidentialStore.selectedSixteenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="presidentialStore.currentLevel === LayerLevel.REGION_TEN_CONSTITUENCY"
              v-model="presidentialStore.selectedTenRegions" :options="dataStore.tenRegions" filter optionLabel="name"
              placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialStore.selectedTenRegions ? presidentialStore.selectedTenRegions.length : 0
                    }}</b>
                  item{{
                    (presidentialStore.selectedTenRegions ? presidentialStore.selectedTenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="presidentialStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="presidentialStore.selectedConstituencies" :options="dataStore.constituencies" filter
              optionLabel="name" placeholder="Select Constituencies" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialStore.selectedConstituencies
                      ? presidentialStore.selectedConstituencies.length
                      : 0
                  }}</b>
                  item{{
                    (presidentialStore.selectedConstituencies
                      ? presidentialStore.selectedConstituencies.length
                      : 0) > 1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <Dropdown v-if="presidentialStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="presidentialStore.selectedGroupedData" :options="dataStore.groupedData" optionLabel="name"
              placeholder="Select Grouped Constituencies" checkmark :highlightOnSelect="false" />

            <Button class="control-button" v-if="showRegionNewButton"
              @click="presidentialStore.setActiveLayer(LayerLevel.REGION_SIXTEEN)" text>
              16 Regions
            </Button>
            <Button class="control-button" v-if="showRegionOldButton"
              @click="presidentialStore.setActiveLayer(LayerLevel.REGION_TEN)" text>
              10 Regions
            </Button>
            <Button class="control-button" v-if="showParliamentaryConstituencyButton"
              @click="presidentialStore.setActiveLayer(LayerLevel.CONSTITUENCY)" text>
              Constituencies
            </Button>

            <Button class="control-button" @click="presidentialStore.fitBounds" text>Resize Map</Button>
            <Button class="control-button" @click="presidentialStore.toggleColorMode" text>Toggle Color</Button>
            <Button class="control-button" @click="resetStore" text>Reset</Button>
          </div>
          <div ref="mapPresidential" class="map-container"></div>
        </div>
      </div>
    </div>

    <div class="parliamentary-container">
      <MapHeader title="PARLIAMENTARY RESULTS MAP" />
      <div class="parliamentary">
        <div class="map-content">
          <div class="map-controls">
            <Dropdown v-model="electionStore.selectedParliamentaryElection"
              :options="electionStore.parliamentaryElections" optionLabel="year" placeholder="Select Year" checkmark
              :highlightOnSelect="true" />
            <MultiSelect v-if="parliamentaryStore.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY"
              v-model="parliamentaryStore.selectedSixteenRegions" :options="dataStore.sixteenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    parliamentaryStore.selectedSixteenRegions ? parliamentaryStore.selectedSixteenRegions.length : 0
                    }}</b>
                  item{{
                    (parliamentaryStore.selectedSixteenRegions ? parliamentaryStore.selectedSixteenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="parliamentaryStore.currentLevel === LayerLevel.REGION_TEN_CONSTITUENCY"
              v-model="parliamentaryStore.selectedTenRegions" :options="dataStore.tenRegions" filter optionLabel="name"
              placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    parliamentaryStore.selectedTenRegions ? parliamentaryStore.selectedTenRegions.length : 0
                    }}</b>
                  item{{
                    (parliamentaryStore.selectedTenRegions ? parliamentaryStore.selectedTenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>

            <MultiSelect v-if="parliamentaryStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="parliamentaryStore.selectedConstituencies" :options="dataStore.constituencies" filter
              optionLabel="name" placeholder="Select Constituencies" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    parliamentaryStore.selectedConstituencies
                      ? parliamentaryStore.selectedConstituencies.length
                      : 0
                  }}</b>
                  item{{
                    (parliamentaryStore.selectedConstituencies
                      ? parliamentaryStore.selectedConstituencies.length
                      : 0) > 1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <Button class="control-button" v-if="showParliamentaryRegionNewButton"
              @click="parliamentaryStore.setActiveLayer(LayerLevel.REGION_SIXTEEN)" text>
              16 Regions
            </Button>
            <Button class="control-button" v-if="showParliamentaryRegionOldButton"
              @click="parliamentaryStore.setActiveLayer(LayerLevel.REGION_TEN)" text>
              10 Regions
            </Button>
            <Button v-if="showParliamentaryConstituencyButton"
              @click="parliamentaryStore.setActiveLayer(LayerLevel.CONSTITUENCY)" text>
              Constituencies
            </Button>
            <Button @click="parliamentaryStore.fitBounds" text>Resize Map</Button>
            <Button @click="parliamentaryStore.toggleColorMode" text>Toggle Color</Button>
            <Button @click="resetStore" text>Reset</Button>
          </div>
          <div ref="mapParliamentary" class="map-container"></div>
        </div>
        <div class="map-statistics">
          <ElectionSummaryLite :year="electionStore.selectedParliamentaryElection.year"
            :summary="electionStore.parliamentaryElectionResult" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Container for each child and its heading */
.presidential-container,
.parliamentary-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}
</style>
