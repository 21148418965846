import Result from '@/modules/core/models/result.class'
import axios from 'axios'
import {
  mapElectionFromElectionDTO,
} from '../mappers/election.mapper'
import type ElectionDto from '../dtos/election.dto'

import parliamentaryResult from '@/assets/election/parliamentary_result.json'
import type { SlipDto, SlipsDto } from '../dtos/slip.dto'
import { openInsightApi } from '@/modules/core/services/api-openinsight.service'
import { mapSlipFromSlipDTO } from '../mappers/slip.mappers'
export default class ParliamentaryElectionService {
  async getParliamentaryElections() {
    try {
      const responses = await Promise.all(
        parliamentaryResult.map((e: string) => {
          return axios.get<ElectionDto>(
            `/data/election/parliamentary_result/${e}.json`
          )
        })
      )
      const elections = []
      for (const response of responses) {
        const election = response.data
        elections.push(election)
      }
      const mappedElections = elections.map((dto) =>
        mapElectionFromElectionDTO(dto)
      )
      return Result.success(mappedElections)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

  async getElectionResult(paginate: boolean = false) {
    try {
      const url = paginate ? '/v1/slips' : '/v1/fetch_all_slips'
      const response = await openInsightApi.get<{ slips: SlipsDto }>(url)
      const electionResults = response.slips.data.map((dto: SlipDto) => mapSlipFromSlipDTO(dto))
      return Result.success(electionResults)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }

}
