// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBc0egknaZeaA67ddo2hEWig1A8RNigzKI",
    authDomain: "openinsight-5d8ef.firebaseapp.com",
    projectId: "openinsight-5d8ef",
    storageBucket: "openinsight-5d8ef.firebasestorage.app",
    messagingSenderId: "155072077084",
    appId: "1:155072077084:web:4f2acc53fdf80f231faed9",
    measurementId: "G-747Z3HDE1G"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const db = getFirestore(app);

export { app, messaging, db };
