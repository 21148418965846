const ElectionModule = () => import('./ElectionModule.vue')
import PresidentialIndex from '@/modules/election/views/PresidentialIndex.vue'
import ParliamentaryIndex from '@/modules/election/views/ParliamentaryIndex.vue'

export const electionModuleRoute = {
  name: 'election',
  path: '/election',
  component: ElectionModule,
  children: [
    {
      path: 'presidential',
      name: 'election.presidential.index',
      component: PresidentialIndex
    },
    {
      path: 'parliamentary',
      name: 'election.parliamentary.index',
      component: ParliamentaryIndex
    },

  ]
}
