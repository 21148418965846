import type AppUserDTO from '../dtos/app-user.dto'
import type AppUser from '../models/app-user.model'


export function mapAppUserFromAppUserDTO(dto: AppUserDTO): AppUser {
  return {
    userType: dto.user_type,
    userId: dto.user_id,
    id: dto.id,
    name: dto.name,
    phoneNumber: dto.phone_number,
  } as AppUser
}
