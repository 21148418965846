export const googleMapsLink = (latitude: number, longitude: number) => {
  const googleMapsBase = 'https://www.google.com/maps'
  const queryParams = `?q=${latitude},${longitude}`
  return `${googleMapsBase}${queryParams}`
}

export const formatCoordinates = (latitude: number, longitude: number) => {
  const roundedLatitude = latitude.toFixed(4)
  const roundedLongitude = longitude.toFixed(4)
  return `${roundedLatitude}, ${roundedLongitude}`
}
