import { VueReCaptcha } from 'vue-recaptcha-v3';
import type { App } from 'vue';

export const setupRecaptcha = (app: App) => {
    app.use(VueReCaptcha, {
        siteKey: "6Lfip3IqAAAAAHBjJZUVIy7TGfTVnwqrq-uogVxY",
        loaderOptions: {
            useRecaptchaNet: true,
            autoHideBadge: false
        }
    });
};