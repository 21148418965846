import Result from '@/modules/core/models/result.class'
import axios from 'axios'
import type { PollingStationDto } from '../dtos/polling-station.dto'
import { finderApi } from '@/modules/core/services/api-finder.service'
import type { PollingCenter } from '../models/polling-center.model'


export default class PollingStationService {
  async getPollingStations() {
    try {
      const response = await axios.get<PollingStationDto[]>('/data/polling_station/voter_count.json')
      const voterCounts = response.data
      return Result.success(voterCounts)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }

  async getPollingStationsCount(region: string) {
    try {
      const response = await axios.get(`/data/polling_station/count/${region}.json`)
      return Result.success(response.data)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log("polling-station.service.ts::getFinderStations::error: ", error)
      return Result.failure(error)
    }
  }

  async getPollingCenters() {
    try {
      const response = await finderApi.get<{ msg: string, stations: PollingCenter[] }>('stations')
      const center = response.stations
      return Result.success(center)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.error("Error::getFinderStations::error: ", error)
      return Result.failure(error)
    }
  }

}
