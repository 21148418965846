import type { BrowserInfo, DeviceInfo, LocationInfo } from "../models/info.model";

export class InfoService {
    static async collectDeviceInfo(): Promise<DeviceInfo> {
        return {
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            deviceMemory: (navigator as any).deviceMemory ?? 0,
            hardwareConcurrency: navigator.hardwareConcurrency ?? 0,
            devicePixelRatio: window.devicePixelRatio,
        };
    }

    static async collectLocationInfo(): Promise<LocationInfo> {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const ipAddress = ipData.ip;

        const locationResponse = await fetch(`https://ipapi.co/${ipAddress}/json/`);
        const locationData = await locationResponse.json();

        return {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            language: navigator.language,
            ipAddress: ipAddress ?? "",
            city: locationData.city ?? "",
            region: locationData.region_name ?? "",
            regionCode: locationData.region_code ?? "",
            country: locationData.country_name ?? "",
            countryCode: locationData.country_code ?? "",
            latitude: locationData.latitude ?? "",
            longitude: locationData.longitude ?? "",
            languages: locationData.languages ?? "",
            org: locationData.org ?? "",
            hostname: locationData.hostname ?? "",
        };
    }

    static async collectBrowserInfo(): Promise<BrowserInfo> {
        const ua = navigator.userAgent;
        const browserInfo = {
            browser: 'unknown',
            browserVersion: 'unknown',
            cookiesEnabled: navigator.cookieEnabled,
            doNotTrack: navigator.doNotTrack ? Boolean(navigator.doNotTrack) : null,
            localStorage: Boolean(window.localStorage),
            sessionStorage: Boolean(window.sessionStorage),
        };

        // Simple browser detection
        if (ua.includes('Chrome')) browserInfo.browser = 'Chrome';
        else if (ua.includes('Firefox')) browserInfo.browser = 'Firefox';
        else if (ua.includes('Safari')) browserInfo.browser = 'Safari';
        else if (ua.includes('Edge')) browserInfo.browser = 'Edge';

        return browserInfo;
    }
}