export default class Result<T> {
  private value: T | Error

  private constructor(value: T | Error) {
    this.value = value
  }

  isSuccess(): boolean {
    return !(this.value instanceof Error)
  }

  isFailure(): boolean {
    return this.value instanceof Error
  }

  getOrNull(): T | null {
    if (this.isFailure()) {
      return null
    }
    return this.value as T
  }

  errorOrNull(): Error | null {
    if (this.isFailure()) {
      return this.value as Error
    }
    return null
  }

  toString(): string {
    if (this.isFailure()) {
      return `Failure(${(this.value as Error).message})`
    } else {
      return `Success(${this.value})`
    }
  }

  static success<T>(value: T): Result<T> {
    return new Result(value)
  }

  static failure<T>(error: T | Error): Result<T> {
    return new Result(error)
  }

  fold<R>(onSuccess: (value: T) => R, onFailure: (error: Error) => R): R {
    if (this.isFailure()) {
      return onFailure(this.value as Error)
    }
    return onSuccess(this.value as T)
  }

  onFailure(action: (error: Error) => void): Result<T> {
    if (this.isFailure()) {
      const error = this.value as Error
      action(error)
    }
    return this
  }

  onSuccess(action: (value: T) => void): Result<T> {
    if (this.isSuccess()) {
      action(this.value as T)
    }
    return this
  }
}
