const HomeModule = () => import('./HomeModule.vue')
const HomeView = () => import('./views/HomeView.vue')


export const homeModuleRoute = {
  path: '/',
  name: 'home',
  component: HomeView,

}
