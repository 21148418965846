import { ref, watch } from 'vue'
import { defineStore } from 'pinia'

import type ElectionResult from '../models/election_result.model'

import PresidentialElectionService from '../services/presidential-election.service'
import ParliamentaryElectionService from '../services/parliamentary-election.service'

import type PartyVotes from '../models/party_votes.model'

import partyInfo from '@/assets/election/party_info.json'
import { ElectionType } from '../enums/election-type.enum'
import type { Slip } from '../models/slip.model'
import { FilterMatchMode } from 'primevue/api'

const presidentialApiService = new PresidentialElectionService()
const parliamentaryApiService = new ParliamentaryElectionService()

export const useElectionIndexStore = defineStore('electionIndex', () => {

  const mDt = ref();
  const mLoading = ref(false);
  const mTotalRecords = ref(0);

  const mPresidentialElectionResults = ref<Slip[]>([])
  const mParliamentaryElectionResults = ref<Slip[]>([])

  const mSelectedElectionResults = ref([]);

  const mSelectAll = ref<boolean>(false);
  const mFirst = ref(0);
  const mFilters = ref(
    {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      region: { value: null, matchMode: FilterMatchMode.IN },
      constituency: { value: null, matchMode: FilterMatchMode.IN },
      pollingStationCode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      pollingStation: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      pinkSheetStampStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
  const mLazyParams = ref({});
  const mColumns = ref([
    { field: 'region', header: 'Region' },
    { field: 'constituency', header: 'Constituency' },
    { field: 'pollingStationCode', header: 'Polling Station Code' },
    { field: 'pollingStation', header: 'Polling Station' },
    { field: 'pinkSheetStampStatus', header: 'Pink Sheet Stamp' },
    { field: 'totalBallotsIssuedA1', header: 'Total Ballots Issued' },
    { field: 'registeredVotersB1', header: 'Registered Voters' },
    { field: 'ballotsIssuedVotersC1', header: 'Ballots Issued Voters' },
    { field: 'rejectedBallotD1', header: 'Rejected Ballots' },
    { field: 'spoiltBallotC3', header: 'Spoilt Ballots' },
    { field: 'totalVotesCastRecordedSnc', header: 'Total Votes Cast Recorded' },
    { field: 'totalValidBallotsRecordedSna', header: 'Total Valid Ballots Recorded' },
    { field: 'biometricVerificationDevice', header: 'Biometric Verification Device' },
    { field: 'manualVerification', header: 'Manual Verification' },
    { field: 'pinkSheetStampStatus', header: 'Pink Sheet Stamp' },
    { field: 'reason', header: 'Reason' },
    { field: 'voterName', header: 'Voter Name' },
  ]);

  const mChartOptions = ref();
  const mPieChartOptions = ref();

  const mVoteChartData = ref();
  const mCountChartData = ref();

  const mRegions = ref<string[]>([])
  const mConstituencies = ref<string[]>([])
  const mPollingStations = ref<string[]>([])
  const mPinkSheetStampStatus = ref<string[]>([])

  const mSelectedSixteenRegions = ref<string[]>([])
  const mSelectedTenRegions = ref<string[]>([])

  const mFilteredConstituencies = ref<string[]>([])
  const mSelectedConstituencies = ref<string[]>([])

  let mApiInterval: ReturnType<typeof setInterval> | null = null;
  const mElectionType: ElectionType | null = null;

  function resetStore() {
  }

  function startInterval(electionType: ElectionType) {
    if (!mApiInterval) {
      electionType === ElectionType.PRESIDENTIAL ? getPresidentialElectionResults : getParliamentaryElectionResults; // Initial call
      mApiInterval = setInterval(electionType, 10000); // 10 seconds
    }
  }

  // Stop the interval
  function stopInterval() {
    if (mApiInterval) {
      clearInterval(mApiInterval);
      mApiInterval = null;
    }
  }


  watch(mSelectedConstituencies, () => {
  })

  const setChartData = (electionType: ElectionType) => {
    const electionResults: Slip[] = electionType === ElectionType.PARLIAMENTARY ? mParliamentaryElectionResults.value : mPresidentialElectionResults.value
    const results = accumulateAndSortVotes(electionResults)

    const lables = results.map((result) => result.party.name)
    const votesData = results.map((result) => result.votes)
    const constData = results.map((result) => countConstituenciesWonByParty(mParliamentaryElectionResults.value, result.party.name))

    mVoteChartData.value = {
      labels: lables,
      datasets:
        [
          {
            label: '',
            data: votesData,
            backgroundColor: getBackgroundColor(results, 0.7),
            borderColor: getBackgroundColor(results),
            borderWidth: 1
          }
        ]
    };

    mCountChartData.value = {
      labels: lables,
      datasets:
        [
          {
            label: '',
            data: constData,
            backgroundColor: getBackgroundColor(results, 0.7),
            borderColor: getBackgroundColor(results),
          }
        ]
    };

  };

  const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    mChartOptions.value = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };

    mPieChartOptions.value = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: textColor
          }
        }
      }
    };
  }

  const loadLazyData = (event: any) => {
    mLoading.value = true;
    mLazyParams.value = { ...mLazyParams.value, first: event?.first || mFirst.value };

    // setTimeout(() => {
    //   CustomerService.getCustomers({ lazyEvent: JSON.stringify(mLazyParams.value) }).then((data) => {
    //     customers.value = data.customers;
    //     mTotalRecords.value = data.totalRecords;
    //     mLoading.value = false;
    //   });
    // }, Math.random() * 1000 + 250);
  };

  const onPage = (event: any) => {
    mLazyParams.value = event;
    loadLazyData(event);
  };

  const onSort = (event: any) => {
    mLazyParams.value = event;
    loadLazyData(event);
  };

  const onFilter = (event: any) => {
    // mLazyParams.value.filters = filters.value;
    loadLazyData(event);
  };

  const onSelectAllChange = (event: any) => {
    mSelectAll.value = event.checked;

    if (mSelectAll.value) {
      mElectionType === ElectionType.PRESIDENTIAL
        ? presidentialApiService.getElectionResult().then(result => {
          result.fold(
            (value) => {
              const electionResults = value as Slip[]
              mPresidentialElectionResults.value = electionResults.map((result, index) => {
                return { ...result, id: index + 1 }
              })
              mConstituencies.value = extractConstituencies(electionResults)
              mRegions.value = extractRegions(electionResults)
              setChartData(ElectionType.PRESIDENTIAL)
            },
            (error) => console.log(error)
          )

        })
        : getParliamentaryElectionResults();

    }
    else {
      mSelectAll.value = false;
      mSelectedElectionResults.value = [];
    }
  };

  const onRowSelect = () => {
    mSelectAll.value = mSelectedElectionResults.value.length === mTotalRecords.value;
  };

  const onRowUnselect = () => {
    mSelectAll.value = false;
  }

  function accumulateAndSortVotes(
    electionResults: Slip[]
  ): PartyVotes[] {
    // const accumulatedPartyVotes: PartyVotes[] = electionResults.reduce(
    //   (acc: PartyVotes[], result) => {
    //     result.partyVotes.forEach((partyVote) => {
    //       if (partyVote.party.name) {
    //         // Check if the party already exists in the accumulator
    //         const existingPartyVote = acc.find((pv) => pv.party.name === partyVote.party.name)
    //         if (existingPartyVote) {
    //           existingPartyVote.votes += partyVote.votes // Accumulate votes
    //         } else {
    //           // If not, add a new entry for the party
    //           acc.push({
    //             party: partyVote.party,
    //             candidate: partyVote.candidate,
    //             votes: partyVote.votes
    //           })
    //         }
    //       }
    //     })
    //     return acc
    //   },
    //   []
    // )

    // // Step 2: Sort the accumulated votes by number of votes in descending order
    // const sortedVotes = accumulatedPartyVotes.sort((a, b) => b.votes - a.votes)

    // // Step 3: Calculate total votes
    // const totalVotes = sortedVotes.reduce((acc, partyVote) => acc + partyVote.votes, 0)

    // // Step 4: Calculate the vote ratio for each party
    // return accumulatedPartyVotes.map((partyVote) => ({
    //   ...partyVote,
    //   votesRatio: totalVotes > 0 ? partyVote.votes / totalVotes : 0
    // }))

    return []
  }

  function countConstituenciesWonByParty(electionResults: Slip[], partyName: string): number {

    // Step 1: Group results by constituency
    // const constituencies = electionResults.reduce(
    //   (acc: { [key: string]: Slip[] }, result) => {
    //     if (result.constituency) {
    //       if (!acc[result.constituency]) {
    //         acc[result.constituency] = []
    //       }
    //       acc[result.constituency].push(result)
    //     }
    //     return acc
    //   },
    //   {}
    // )

    // let constituenciesWon = 0

    // // Step 2: For each constituency, accumulate votes and find the winner without sorting
    // Object.values(constituencies).forEach((constituencyResults) => {
    //   const voteMap: { [partyName: string]: number } = {}

    //   // Accumulate votes for each party in this constituency
    //   constituencyResults.forEach((result) => {
    //     result.partyVotes.forEach((partyVote) => {
    //       if (partyVote.party.name) {
    //         voteMap[partyVote.party.name] = (voteMap[partyVote.party.name] || 0) + partyVote.votes
    //       }
    //     })
    //   })

    //   // Step 3: Find the party with the maximum votes
    //   let maxVotes = 0
    //   let winningParty = ''

    //   for (const [currentPartyName, votes] of Object.entries(voteMap)) {
    //     if (votes > maxVotes) {
    //       maxVotes = votes
    //       winningParty = currentPartyName
    //     }
    //   }

    //   // Step 4: Check if the winning party is the party we are interested in
    //   if (winningParty === partyName) {
    //     constituenciesWon++
    //   }
    // })

    // return constituenciesWon

    return 0;
  }

  function getBackgroundColor(partyVotes: PartyVotes[], opacity: number = 1): string[] {
    return partyVotes.map((result) => {
      const info = partyInfo.find((p) => p.name === result.party.name)
      const color = hexToRgb(info?.color ?? '')
      console.log(color)
      return `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${opacity})`
    })
  }


  function hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    // Remove the leading '#' if it's there
    let cleanedHex = hex.replace(/^#/, '');

    // Check if it's a valid 3 or 6 character hex code
    if (cleanedHex.length === 3) {
      cleanedHex = cleanedHex.split('').map(char => char + char).join('');
    }

    if (cleanedHex.length !== 6) {
      return null; // Invalid hex code
    }

    const r = parseInt(cleanedHex.substring(0, 2), 16);
    const g = parseInt(cleanedHex.substring(2, 4), 16);
    const b = parseInt(cleanedHex.substring(4, 6), 16);

    return { r, g, b };
  }

  const getPresidentialElectionResults = async () => {
    mLoading.value = true;

    const result = await presidentialApiService.getElectionResult()

    result.fold(
      (value) => {
        const electionResults = value as Slip[]
        mPresidentialElectionResults.value = electionResults
        extractResultData(electionResults)
        setChartData(ElectionType.PRESIDENTIAL)

        mLoading.value = false;
      },
      (error) => {
        mLoading.value = false;
        console.error(error);
      }
    )
  }

  const getParliamentaryElectionResults = async () => {
    mLoading.value = true;

    const result = await parliamentaryApiService.getElectionResult()

    result.fold(
      (value) => {
        const electionResults = value as Slip[]
        mParliamentaryElectionResults.value = electionResults
        extractResultData(electionResults)
        setChartData(ElectionType.PARLIAMENTARY)

        mLoading.value = false;
      },
      (error) => {
        mLoading.value = false;
        console.error(error);
      }
    )
  }


  function extractResultData(results?: Slip[] | null) {
    mConstituencies.value = extractConstituencies(results)
    mRegions.value = extractRegions(results)
    mPinkSheetStampStatus.value = extractPinkSheetStatus(results)
  }

  function extractRegions(results?: Slip[] | null): string[] {
    if (results) {
      const regions = results
        .map((result: Slip) => result.region)
        .filter((region) => region !== undefined) // Filter out undefined regions
      return Array.from(new Set(regions))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort the regions by name
        .map((region) => region) // Remove duplicates with incremental id
    }
    return []
  }

  function extractConstituencies(results?: Slip[] | null): string[] {
    if (results) {
      const constituencies = results
        .map((result: Slip) => result.constituency)
        .filter((constituency) => constituency !== undefined)

      return Array.from(new Set(constituencies))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort by constituency name
        .map((constituency) => constituency)
    }
    return []
  }

  function extractPinkSheetStatus(results?: Slip[] | null): string[] {
    if (results) {
      const statues = results
        .map((result: Slip) => result.pinkSheetStampStatus)
        .filter((status) => status !== undefined)

      return Array.from(new Set(statues))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort by constituency name
        .map((status) => status)
    }
    return []
  }

  return {
    isLoading: mLoading,
    presidentialElectionResults: mPresidentialElectionResults,
    parliamentaryElectionResults: mParliamentaryElectionResults,
    regions: mRegions,
    constituencies: mConstituencies,
    pollingStations: mPollingStations,
    pinkSheetStampStatus: mPinkSheetStampStatus,
    selectedSixteenRegions: mSelectedSixteenRegions,
    selectedTenRegions: mSelectedTenRegions,
    filteredConstituencies: mFilteredConstituencies,
    selectedConstituencies: mSelectedConstituencies,
    chartOptions: mChartOptions,
    pieChartOptions: mPieChartOptions,
    voteBarChartData: mVoteChartData,
    countBarChartData: mCountChartData,
    getPresidentialElectionResults,
    getParliamentaryElectionResults,
    setChartOptions,
    startInterval,
    stopInterval,
    resetStore
  }
})
