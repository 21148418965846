import type VoterDto from '../dtos/voter.dto'
import type VotersDto from '../dtos/voters.dto'
import type Voter from '../models/voter.model'
import type Voters from '../models/voters.model'

export function mapVoterFromVoterDTO(dto?: VoterDto | null): Voter | null {
  return !dto
    ? null
    : ({
      year: dto.year,
      voters: dto.voters.map(mapVotersDtoFromVotersDtoDTO)
    } as Voter)
}

export function mapVotersDtoFromVotersDtoDTO(dto?: VotersDto | null): Voters | null {
  return !dto
    ? null
    : ({
      region: dto.region,
      constituency: dto.constituency,
      totalVoters: dto.total_voters,
      totalVotersMale: dto.total_voters_male,
      totalVotersFemale: dto.total_voters_female,
      voters: dto.voters,
      votersMale: dto.voters_male,
      votersFemale: dto.voters_female
    } as Voters)
}
