<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import 'leaflet/dist/leaflet.css'
import '@/assets/styles/map/styles.css';

import { LayerLevel } from '@/modules/map/enums/layer-level.enum'

import { useDataStore } from '../../data/stores/data.store'
import { useElectionStore } from '../../election/stores/election-map.store'
import { useVoterStore } from '../../voter/stores/voter.store'
import { useMapUiStore } from '../../map/stores/ui.store'
import { usePresidentialMapStore } from '../stores/presidential-map.store'


import ElectionSummary from '@/modules/election/components/ElectionSummary.vue'

import MapHeader from '../components/MapHeader.vue'
import Legend from '../components/Legend.vue'

const mapContainer = ref<HTMLElement | null>(null)
const dataStore = useDataStore()
const electionStore = useElectionStore()
const voterStore = useVoterStore()
const mapUiStore = useMapUiStore()
const presidentialStore = usePresidentialMapStore()

const showRegionNewButton = computed(() => presidentialStore.currentLevel !== LayerLevel.REGION_SIXTEEN)
const showRegionOldButton = computed(() => presidentialStore.currentLevel !== LayerLevel.REGION_TEN)

const showConstituencyButton = computed(
  () =>
    presidentialStore.currentLevel !== LayerLevel.CONSTITUENCY &&
    presidentialStore.currentLevel !== LayerLevel.REGION_SIXTEEN_CONSTITUENCY
)

function resetStore() {
  electionStore.resetStore()
  voterStore.resetStore()
  mapUiStore.resetStore()
  presidentialStore.resetStore()
}


onMounted(async () => {
  await Promise.all([
    dataStore.getShapeFiles(),
    dataStore.getGroupedData(),
    electionStore.getPresidentialElections(),
    voterStore.getVoters()
  ]);
  if (mapContainer.value) {
    presidentialStore.initializeMap(mapContainer.value)
    presidentialStore.initializeLayers()
  }
})

const mapHeader = computed(() => {
  const year = electionStore.selectedPresidentialElection.year
  return `PRESIDENTIAL RESULTS MAP ${year}`
})
</script>

<template>
  <div class="parent">
    <div class="presidential-container">
      <MapHeader :title="mapHeader" />

      <div class="presidential">
        <div class="map-statistics">
          <div class="map-statistics-container">
            <div class="map-statistics-content">
              <ElectionSummary :year="electionStore.selectedPresidentialElection.year"
                :summary="electionStore.presidentialElectionResult" />
            </div>
            <div class="map-statistics-legend">
              <Button class="control-button" @click="presidentialStore.toggleLegend" text>
                {{ presidentialStore.isLegendVisible ? 'Hide Legend' : 'Show Legend' }}
              </Button>
              <Legend v-if="presidentialStore.isLegendVisible" title="Win Margin" :items="presidentialStore.legendItems"
                :horizontal="true" />
            </div>
          </div>

        </div>

        <div class="map-content">
          <div class="map-controls">
            <Dropdown v-model="electionStore.selectedPresidentialElection"
              :options="electionStore.presidentialElections" optionLabel="year" placeholder="Select Year" checkmark
              :highlightOnSelect="true" />
            <MultiSelect v-if="presidentialStore.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY"
              v-model="presidentialStore.selectedSixteenRegions" :options="dataStore.sixteenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialStore.selectedSixteenRegions ? presidentialStore.selectedSixteenRegions.length : 0
                    }}</b>
                  item{{
                    (presidentialStore.selectedSixteenRegions ? presidentialStore.selectedSixteenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="presidentialStore.currentLevel === LayerLevel.REGION_TEN_CONSTITUENCY"
              v-model="presidentialStore.selectedTenRegions" :options="dataStore.tenRegions" filter optionLabel="name"
              placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialStore.selectedTenRegions ? presidentialStore.selectedTenRegions.length : 0
                    }}</b>
                  item{{
                    (presidentialStore.selectedTenRegions ? presidentialStore.selectedTenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="presidentialStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="presidentialStore.selectedConstituencies" :options="dataStore.constituencies" filter
              optionLabel="name" placeholder="Select Constituencies" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialStore.selectedConstituencies
                      ? presidentialStore.selectedConstituencies.length
                      : 0
                  }}</b>
                  item{{
                    (presidentialStore.selectedConstituencies
                      ? presidentialStore.selectedConstituencies.length
                      : 0) > 1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <Dropdown v-if="presidentialStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="presidentialStore.selectedGroupedData" :options="dataStore.groupedData" optionLabel="name"
              placeholder="Select Grouped Constituencies" checkmark :highlightOnSelect="true" />

            <Button class="control-button" v-if="showRegionNewButton"
              @click="presidentialStore.setActiveLayer(LayerLevel.REGION_SIXTEEN)" text>
              16 Regions
            </Button>
            <Button class="control-button" v-if="showRegionOldButton"
              @click="presidentialStore.setActiveLayer(LayerLevel.REGION_TEN)" text>
              10 Regions
            </Button>
            <Button class="control-button" v-if="showConstituencyButton"
              @click="presidentialStore.setActiveLayer(LayerLevel.CONSTITUENCY)" text>
              Constituencies
            </Button>

            <Button class="control-button" @click="presidentialStore.fitBounds" text>Resize Map</Button>
            <Button class="control-button" @click="presidentialStore.toggleColorMode" text>Toggle Color</Button>
            <Button class="control-button" @click="resetStore" text>Reset</Button>
          </div>

          <div ref="mapContainer" class="map-container"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped></style>
