<script setup lang="ts">
import { computed } from 'vue'

import type ElectionSummary from '../models/election_summary.model';


interface Props {
    year: string;
    summary: ElectionSummary | null;
}

const props = defineProps<Props>()

const firstLeadingPartyStyle = computed(() => {
    return {
        borderLeft: `5px solid ${props.summary?.firstLeadingParty.color}`,
        color: props.summary?.firstLeadingParty.color as string
    };
});

const secondLeadingPartyStyle = computed(() => {
    return {
        borderRight: `5px solid ${props.summary?.secondLeadingParty.color}`,
        color: props.summary?.secondLeadingParty.color as string,
        justifyContent: 'flex-end', // To align candidate-info closer to the image
    };
});


</script>
<template>

    <div class="election-results">
        <h1 class="title">{{ props.year }} RESULTS</h1>
        <div class="results-bar">
            <!-- Candidate Divs -->
            <div class="candidates">
                <div class="candidate first-leading-party" :style="firstLeadingPartyStyle">
                    <img :src="props.summary?.firstLeadingParty.image" alt="Party" class="candidate-image" />
                    <div class="candidate-info">
                        <span class="electoral-votes">{{
                            props.summary?.firstLeadingParty.name
                        }}: {{
                                props.summary?.firstLeadingParty.constituencyWon }}</span>
                        <span class="vote-percentage">{{
                            props.summary?.firstLeadingParty.percentage }}%</span>
                        <span class="vote-count">{{
                            props.summary?.firstLeadingParty.votes.toLocaleString()
                        }}</span>
                    </div>
                </div>
                <div class="candidate second-leading-party" :style="secondLeadingPartyStyle">
                    <div class="candidate-info">
                        <span class="electoral-votes"> {{
                            props.summary?.secondLeadingParty.name }}: {{
                                props.summary?.secondLeadingParty.constituencyWon }}</span>
                        <span class="vote-percentage">{{
                            props.summary?.secondLeadingParty.percentage }}%</span>
                        <span class="vote-count">{{
                            props.summary?.secondLeadingParty.votes.toLocaleString()
                        }}</span>
                    </div>
                    <img :src="props.summary?.secondLeadingParty.image" alt="Party" class="candidate-image" />
                </div>
            </div>

            <!-- Results Section -->
            <div class="results-section">
                <div class="win-margin">
                    Win margin:
                    <span class="margin-number">{{ props.summary?.margin.toLocaleString()
                        }}</span>
                    electoral votes (<span class="margin-percentage">{{
                        props.summary?.marginPercentage.toFixed(2)
                    }}%</span>)
                </div>
                <div class="others">
                    <span class="vote-count">OTHERS</span>
                    <span class="vote-percentage"> | {{
                        props.summary?.otherLeadingParty.percentage }}%</span>
                    <span class="vote-count"> | {{
                        props.summary?.otherLeadingParty.votes.toLocaleString()
                        }}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
.election-results {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
}

.title {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.results-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.candidates {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.candidate {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
}


.candidate-image {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
}

.candidate.second-leading-party .candidate-image {
    margin-left: 15px;
    margin-right: 0;
    /* Ensure the second-leading-party image has left margin and no right margin */
}


.candidate-info .electoral-votes,
.candidate-info .vote-percentage,
.candidate-info .vote-count {
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 5px;
}

.results-section {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.win-margin {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
}

.margin-number,
.margin-percentage {
    font-weight: bold;
}

.others {
    margin-top: 15px;
    color: #DAA520;
    font-size: 1.4rem;
    font-weight: bold;
}

@media (max-width: 768px) {
    .candidates {
        width: 100%;
    }
}
</style>
