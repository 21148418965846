<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue'
import 'leaflet/dist/leaflet.css'
import '@/assets/styles/map/styles.css';

import { LayerLevel } from '@/modules/map/enums/layer-level.enum'
import { ElectionType } from '@/modules/election/enums/election-type.enum'

import { useDataStore } from '../../../data/stores/data.store'
import { useElectionMapLiveStore } from '../../../election/stores/live/election-map-live.store'
import { useVoterStore } from '../../../voter/stores/voter.store'
import { useMapUiStore } from '../../stores/ui.store'
import { usePresidentialMapLiveStore } from '../../stores/live/presidential-map-live.store'

const mapContainer = ref<HTMLElement | null>(null)
const dataStore = useDataStore()
const electionStore = useElectionMapLiveStore()
const voterStore = useVoterStore()
const mapUiStore = useMapUiStore()
const presidentialLiveMapStore = usePresidentialMapLiveStore()

const showRegionNewButton = computed(() => presidentialLiveMapStore.currentLevel !== LayerLevel.REGION_SIXTEEN)
const showRegionOldButton = computed(() => presidentialLiveMapStore.currentLevel !== LayerLevel.REGION_TEN)

const showConstituencyButton = computed(
  () =>
    presidentialLiveMapStore.currentLevel !== LayerLevel.CONSTITUENCY &&
    presidentialLiveMapStore.currentLevel !== LayerLevel.REGION_SIXTEEN_CONSTITUENCY
)

function resetStore() {
  voterStore.resetStore()
  mapUiStore.resetStore()
  presidentialLiveMapStore.resetStore()
}

// Define observer outside the lifecycle hooks
let observer: IntersectionObserver | null = null;

const observerCallback = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      electionStore.startInterval(ElectionType.PRESIDENTIAL); // Start API polling
    } else {
      electionStore.stopInterval(); // Stop API polling
    }
  });
};

onMounted(async () => {
  await Promise.all([
    dataStore.getShapeFiles(),
    dataStore.getGroupedData(),
    voterStore.getVoters()
  ]);
  observer = new IntersectionObserver(observerCallback);
  if (mapContainer.value) {
    presidentialLiveMapStore.initializeMap(mapContainer.value)
    presidentialLiveMapStore.initializeLayers()
    observer.observe(mapContainer.value);
  }
})

onUnmounted(() => {
  if (observer && mapContainer.value) {
    observer.unobserve(mapContainer.value);
  }
  electionStore.stopInterval(); // Ensure interval stops on unmount
  observer = null;
});
</script>

<template>
  <div class="parent">
    <div class="presidential-container">
      <Tag icon="pi pi-exclamation-triangle" severity="warning" value="WORK IN PROGRESS - DO NOT USE"
        class="work-in-progress" />

      <div class="presidential">
        <div class="map-statistics">
          <TabView class="tab-view" v-model:activeIndex="mapUiStore.activeTab">
            <TabPanel header="Results">
              <div class="top-content">

              </div>
            </TabPanel>

          </TabView>
        </div>

        <div class="map-content">
          <div class="map-controls">

            <MultiSelect v-if="presidentialLiveMapStore.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY"
              v-model="presidentialLiveMapStore.selectedSixteenRegions" :options="dataStore.sixteenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialLiveMapStore.selectedSixteenRegions ?
                      presidentialLiveMapStore.selectedSixteenRegions.length : 0
                  }}</b>
                  item{{
                    (presidentialLiveMapStore.selectedSixteenRegions ?
                      presidentialLiveMapStore.selectedSixteenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="presidentialLiveMapStore.currentLevel === LayerLevel.REGION_TEN_CONSTITUENCY"
              v-model="presidentialLiveMapStore.selectedTenRegions" :options="dataStore.tenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialLiveMapStore.selectedTenRegions ? presidentialLiveMapStore.selectedTenRegions.length : 0
                  }}</b>
                  item{{
                    (presidentialLiveMapStore.selectedTenRegions ? presidentialLiveMapStore.selectedTenRegions.length : 0)
                      >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="presidentialLiveMapStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="presidentialLiveMapStore.selectedConstituencies" :options="dataStore.constituencies" filter
              optionLabel="name" placeholder="Select Constituencies" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    presidentialLiveMapStore.selectedConstituencies
                      ? presidentialLiveMapStore.selectedConstituencies.length
                      : 0
                  }}</b>
                  item{{
                    (presidentialLiveMapStore.selectedConstituencies
                      ? presidentialLiveMapStore.selectedConstituencies.length
                      : 0) > 1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <Dropdown v-if="presidentialLiveMapStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="presidentialLiveMapStore.selectedGroupedData" :options="dataStore.groupedData" optionLabel="name"
              placeholder="Select Grouped Constituencies" checkmark :highlightOnSelect="true" />

            <Button class="control-button" v-if="showRegionNewButton"
              @click="presidentialLiveMapStore.setActiveLayer(LayerLevel.REGION_SIXTEEN)" text>
              16 Regions
            </Button>
            <Button class="control-button" v-if="showRegionOldButton"
              @click="presidentialLiveMapStore.setActiveLayer(LayerLevel.REGION_TEN)" text>
              10 Regions
            </Button>
            <Button class="control-button" v-if="showConstituencyButton"
              @click="presidentialLiveMapStore.setActiveLayer(LayerLevel.CONSTITUENCY)" text>
              Constituencies
            </Button>

            <Button class="control-button" @click="presidentialLiveMapStore.fitBounds" text>Resize Map</Button>
            <Button class="control-button" @click="presidentialLiveMapStore.toggleColorMode" text>Toggle Color</Button>
            <Button class="control-button" @click="resetStore" text>Reset</Button>
          </div>

          <div ref="mapContainer" class="map-container"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
.work-in-progress {
  font-size: 1.1rem;
}
</style>
