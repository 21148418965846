<script setup lang="ts">
import { computed } from 'vue'

import type ElectionSummary from '../models/election_summary.model';
import ElectoralCard from '@/modules/election/components/ElectoralCard.vue'


interface Props {
    year: string;
    summary: ElectionSummary | null;
}

const props = defineProps<Props>()

const firstLeadingPartyStyle = computed(() => ({
    color: props.summary?.firstLeadingParty.color as string
}))

const secondLeadingPartyStyle = computed(() => ({
    color: props.summary?.secondLeadingParty.color as string
}))

const otherLeadingPartyStyle = computed(() => ({
    color: '#DAA520'
}))

const firstLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.firstLeadingParty.percentage}%`,
    backgroundColor: props.summary?.firstLeadingParty.color as string
}))

const secondLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.secondLeadingParty.percentage}%`,
    backgroundColor: props.summary?.secondLeadingParty.color as string
}))

const otherLeadingPartyBarStyle = computed(() => ({
    width: `${props.summary?.otherLeadingParty.percentage}%`,
    backgroundColor: props.summary?.otherLeadingParty.color,
    left: `${props.summary?.firstLeadingParty.percentage}%`
}))

</script>
<template>
    <div class="election-results">
        <h1 class="title">{{ props.year }} RESULTS</h1>
        <div class="results-bar">
            <!-- Candidate Divs -->
            <!-- <div class="candidates">
                <div class="candidate first-leading-party" :style="firstLeadingPartyStyle">
                    <img :src="props.summary?.firstLeadingParty.image" alt="Party" class="candidate-image" />
                    <div class="candidate-info">
                        <span class="electoral-votes">{{
                            props.summary?.firstLeadingParty.name
                        }}: {{
                                props.summary?.firstLeadingParty.constituencyWon }}</span>
                        <span class="vote-percentage">{{
                            props.summary?.firstLeadingParty.percentage }}%</span>
                        <span class="vote-count">{{
                            props.summary?.firstLeadingParty.votes.toLocaleString()
                        }}</span>
                    </div>
                </div>
                <div class="candidate second-leading-party" :style="secondLeadingPartyStyle">
                    <div class="candidate-info">
                        <span class="electoral-votes"> {{
                            props.summary?.secondLeadingParty.name }}: {{
                                props.summary?.secondLeadingParty.constituencyWon }}</span>
                        <span class="vote-percentage">{{
                            props.summary?.secondLeadingParty.percentage }}%</span>
                        <span class="vote-count">{{
                            props.summary?.secondLeadingParty.votes.toLocaleString()
                        }}</span>
                    </div>
                    <img :src="props.summary?.secondLeadingParty.image" alt="Party" class="candidate-image" />
                </div>
            </div> -->

            <ElectoralCard :style="firstLeadingPartyStyle" :name="props.summary?.firstLeadingParty.name"
                party="Democratic" :electoral-votes="props.summary?.firstLeadingParty.constituencyWon"
                :popular-votes="props.summary?.firstLeadingParty.votes"
                :image-url="props.summary?.firstLeadingParty.image" :color="props.summary?.firstLeadingParty.color" />

            <!-- Results Section -->
            <div class="results-section">
                <div class="bar">
                    <div class="first-leading-party-bar" :style="firstLeadingPartyBarStyle">
                        <span class="percentage-label">{{ props.summary?.firstLeadingParty.percentage }}%</span>
                    </div>
                    <div class="other-leading-party-bar" :style="otherLeadingPartyBarStyle"></div>
                    <div class="second-leading-party-bar" :style="secondLeadingPartyBarStyle">
                        <span class="percentage-label">{{ props.summary?.secondLeadingParty.percentage }}%</span>
                    </div>
                </div>
            </div>

            <ElectoralCard :style="secondLeadingPartyStyle" :name="props.summary?.secondLeadingParty.name"
                party="Democratic" :electoral-votes="props.summary?.secondLeadingParty.constituencyWon"
                :popular-votes="props.summary?.secondLeadingParty.votes"
                :image-url="props.summary?.secondLeadingParty.image" :color="props.summary?.secondLeadingParty.color" />

            <!-- Results Section -->
            <div class="results-section">
                <div class="win-margin">
                    Win margin:
                    <span class="margin-number">{{ props.summary?.margin.toLocaleString()
                        }}</span>
                    electoral votes (<span class="margin-percentage">{{
                        props.summary?.marginPercentage.toFixed(2)
                        }}%</span>)
                </div>
                <div class="others" :style="otherLeadingPartyStyle">
                    <div class="candidate-info">
                        <span class="vote-count">OTHERS</span>
                        <span class="vote-percentage"> | {{
                            props.summary?.otherLeadingParty.percentage }}%</span>
                        <span class="vote-count"> | {{
                            props.summary?.otherLeadingParty.votes.toLocaleString()
                            }}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<style scoped>
.election-results {
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.title {
    font-size: 4.8rem;
    font-weight: bold;
    text-align: center;
    margin: 25px 0px 20px 0px;
    /* margin-top: 0; */
    color: #636363;
    letter-spacing: 1px;
}

.results-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 45px;
}

.candidates {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
}

.candidate {
    display: flex;
    align-items: center;
    width: 45%;
    min-width: 150px;
}

.candidate-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 13px;
}

.candidate-info {
    display: flex;
    flex-direction: column;
}

.electoral-votes {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 15px;
    letter-spacing: 1px;
}

.candidate-name {
    font-weight: bold;
    font-size: 1rem;
}

.vote-percentage,
.vote-count {
    font-size: 2.1rem;
    font-weight: bold;
}

.vote-percentage {
    margin-bottom: 15px;
    letter-spacing: 1px;
}


.vote-count {
    letter-spacing: 1px;
}

.results-section {
    width: 100%;
    margin-top: 10px;
}

.bar {
    height: 35px;
    background-color: #f0f0f0;
    border-radius: 0.5rem;
    position: relative;
    overflow: hidden;
    margin: 15px 0;
}

.first-leading-party-bar,
.second-leading-party-bar,
.other-leading-party-bar {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
}

.first-leading-party-bar {
    left: 0;
}

.second-leading-party-bar {
    right: 0;
}

.middle-line {
    position: absolute;
    top: 0;
    left: 50%;
    width: 3px;
    height: 100%;
    background-color: #000;
}

.divider-line {
    width: 8px;
    height: 100%;
    background-color: #EEE;
}

.pointer {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid #000;
}

.win-marker {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1rem;
    white-space: nowrap;
    margin-top: 10px;
}

.win-margin {
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
    margin-bottom: 30px;
}

.second-leading-party {
    flex-direction: row-reverse;
    text-align: right;
}

.others {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0;
}

.others .candidate-info {
    display: inline-block;
}

.percentage-label {
    padding: 0 5px;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }

    .candidate {
        width: 100%;
    }

    .candidate-image {
        width: 70px;
        height: 70px;
        margin-right: 10px;
    }

    .electoral-votes {
        font-size: 1.5rem;
    }

    .vote-percentage,
    .vote-count {
        font-size: 1rem;
        margin-bottom: 120px;
    }

    .bar {
        height: 30px;
    }

    .pointer {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #000;
    }

    .win-marker {
        font-size: 0.8rem;
        margin-top: 8px;
    }

    .win-margin {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 1.5rem;
    }

    .candidate-image {
        width: 50px;
        height: 50px;
    }

    .electoral-votes {
        font-size: 1.2rem;
    }

    .vote-percentage,
    .vote-count {
        font-size: 0.9rem;
    }

    .bar {
        height: 20px;
    }

    .pointer {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #000;
    }

    .win-marker {
        font-size: 0.7rem;
    }

    .win-margin {
        font-size: 0.9rem;
    }
}
</style>
