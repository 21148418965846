import { ref } from 'vue'
import { defineStore } from 'pinia'

import type { PollingStation } from '../models/polling-station.model'

import PollingStationService from '../services/polling-station.service'


const pollingStationService = new PollingStationService()

export const usePollingStationStore = defineStore('pollingStation', () => {

    const mPollingStations = ref<PollingStation[]>([])

    const getPollingStations = async () => {

        const result = await pollingStationService.getPollingStations()

        result.fold(
            (value) => {
                mPollingStations.value = value as PollingStation[]
            },
            (error) => console.log(error)
        )
    }

    const getFinderStations = async () => {

        const result = await pollingStationService.getPollingCenters()

        result.fold(
            (value) => {
                mPollingStations.value = value as PollingStation[]
            },
            (error) => console.log(error)
        )
    }

    return {
        pollingStations: mPollingStations,
        getPollingStations,
        getFinderStations
    }
})