import { trimAndUppercase } from '@/modules/core/utils/string.util'
import type PartyVotesDto from '../dtos/party-votes.dto'
import type PartyVotes from '../models/party_votes.model'

export function mapPartyVotesFromPartyVotesDTO(dto?: PartyVotesDto | null): PartyVotes | null {
  return !dto
    ? null
    : {
      party: {
        name: trimAndUppercase(dto.name)
      },
      candidate: trimAndUppercase(dto.candidate ?? ''),
      votes: dto.votes
    } as PartyVotes
}
