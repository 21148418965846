import { trimAndUppercase } from '@/modules/core/utils/string.util'
import type ElectionDto from '../dtos/election.dto'
import type { ElectionResultDto, ElectionResultRemoteDto } from '../dtos/election-result.dto'
import type Election from '../models/election.model'
import type ElectionResult from '../models/election_result.model'
import { mapPartyVotesFromPartyVotesDTO } from './party.mapper'
import type { PollingStation } from '@/modules/polling_station/models/polling-station.model'

export function mapElectionFromElectionDTO(
  dto?: ElectionDto | null
): Election | null {
  return !dto
    ? null
    : ({
      year: dto.year,
      results: dto.results.map(mapElectionResultFromElectionResultDTO)
    } as Election)
}

export function mapElectionResultFromElectionResultDTO(
  dto?: ElectionResultDto | null
): ElectionResult | null {
  return !dto
    ? null
    : ({
      id: 0,
      region: trimAndUppercase(dto.region),
      constituency: trimAndUppercase(dto.constituency),
      registeredVoters: dto.total_registered_voters,
      totalCastVotes: dto.total_cast_votes,
      totalValidVotes: dto.total_valid_votes,
      totalRejectedVotes: dto.total_rejected_votes,
      turnout: dto.turnout,
      abstained: dto.abstained,
      partyVotes: dto.parties.map(mapPartyVotesFromPartyVotesDTO),
    } as ElectionResult)
}
export function mapElectionResultFromElectionResultRemoteDTO(
  dto?: ElectionResultRemoteDto | null
): ElectionResult | null {
  return !dto
    ? null
    : ({
      id: 0,
      region: trimAndUppercase(dto.region_name),
      constituency: trimAndUppercase(dto.constituency_name),
      pollingStation: {
        code: trimAndUppercase(dto.polling_station_code),
        name: trimAndUppercase(dto.polling_station_name),
      } as PollingStation,
      registeredVoters: 0,
      totalCastVotes: 0,
      totalValidVotes: 0,
      totalRejectedVotes: 0,
      turnout: 0,
      imageUrl: dto.image_url,
      link: dto.link,
    } as ElectionResult)
}
