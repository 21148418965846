<script setup lang="ts">
import { computed } from 'vue'
interface Props {
  name?: string
  party: string
  electoralVotes?: number
  popularVotes?: number
  imageUrl?: string
  color?: string
}

const props = defineProps<Props>()

const cardBackgroundStyle = computed(() => ({
  backgroundColor: `${props.color}4D`
}))
const nameStyle = computed(() => ({
  color: `${props.color}`
}))
</script>

<template>
  <div class="electoral-card" :style="cardBackgroundStyle">
    <div class="content">
      <h2 class="name" :style="nameStyle">{{ name }}</h2>
      <div class="party-tag">{{ }}</div>

      <div class="votes-container">
        <div class="vote-section">
          <div class="vote-number">{{ electoralVotes }}</div>
          <div class="vote-label">Constituency</div>
        </div>

        <div class="vote-section">
          <div class="vote-number">{{ popularVotes ? popularVotes.toLocaleString() : '' }}</div>
          <div class="vote-label">Votes</div>
        </div>
      </div>
    </div>
    <div class="image-container">
      <img :src="imageUrl" :alt="`Photo of ${name}`" class="candidate-image" />
    </div>
  </div>
</template>

<style>
.electoral-card {
  display: flex;
  align-items: stretch;
  border-radius: 0.75rem;
  padding: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  margin: 1rem 1rem;
  overflow: hidden;
  position: relative;
  height: 150px;
  /* Set height to match image */
}

.electoral-card .content {
  flex-grow: 1;
  padding: 1rem;
  max-width: calc(100% - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.electoral-card .name {
  font-size: 2rem;
  /* Reduced from 2.2rem */
  font-weight: 600;
  margin-bottom: 0.25rem;
  line-height: 1.2;
}

.electoral-card .party-tag {
  display: inline-block;
  background-color: #000;
  color: white;
  text-transform: uppercase;
  padding: 0.15rem 0.5rem;
  /* Reduced padding */
  border-radius: 0.25rem;
  font-size: 0.75rem;
  /* Reduced from 0.875rem */
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
  /* Reduced from 1.5rem */
  width: 0%;
}

.electoral-card .votes-container {
  display: flex;
  gap: 1.8rem;
  /* Reduced from 2rem */
  align-items: center;
}

.electoral-card .vote-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.electoral-card .vote-number {
  font-size: 2.6rem;
  /* Reduced from 3.8rem */
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.25rem;
  /* Reduced from 0.5rem */
}

.electoral-card .vote-label {
  color: #4b5563;
  font-size: 0.9rem;
  /* Reduced from 1.2rem */
  align-items: center;
  justify-content: center;
}

.electoral-card .image-container {
  position: relative;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

.electoral-card .candidate-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .electoral-card {
    flex-direction: column;
    height: auto;
  }

  .electoral-card .content {
    max-width: 100%;
    padding: 1rem;
  }

  .electoral-card .image-container {
    width: 100%;
    height: 150px;
    order: -1;
  }

  .electoral-card .votes-container {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .electoral-card .vote-number {
    font-size: 1.75rem;
  }

  .electoral-card .name {
    font-size: 1.25rem;
  }
}

/* Hover effects */
.electoral-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.electoral-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Optional animation for numbers */
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.electoral-card .vote-number {
  animation: countUp 0.5s ease-out forwards;
}
</style>