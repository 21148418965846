import { ref } from 'vue'
import { defineStore } from 'pinia'

import type { PollingCenter } from '../models/polling-center.model'

import PollingStationService from '../services/polling-station.service'

const pollingStationService = new PollingStationService()

export const usePollingCenterIndexStore = defineStore('pollingCenterIndex', () => {

    const mIsLoading = ref<boolean>(false)

    const mPollingCenters = ref<PollingCenter[]>([])

    const mRegions = ref<string[]>([])
    const mConstituencies = ref<string[]>([])

    let mApiInterval: ReturnType<typeof setInterval> | null = null;

    // Start the interval
    function startInterval() {
        if (!mApiInterval) {
            mApiInterval = setInterval(getPollingCenters, 10000); // 10 seconds
        }
    }

    // Stop the interval
    function stopInterval() {
        if (mApiInterval) {
            clearInterval(mApiInterval);
            mApiInterval = null;
        }
    }

    const getPollingCenters = async () => {
        mIsLoading.value = true

        const result = await pollingStationService.getPollingCenters()

        result.fold(
            (value) => {
                mPollingCenters.value = value as PollingCenter[]
                mRegions.value = extractRegions(mPollingCenters.value)
                mConstituencies.value = extractConstituencies(mPollingCenters.value)
                mIsLoading.value = false;
            },
            (error) => {
                mIsLoading.value = false;
                console.error(error);
            }
        )
    }

    function extractRegions(centers?: PollingCenter[] | null): string[] {
        if (centers) {
            const regions = centers
                .map((center: PollingCenter) => center.region)
                .filter((region) => region !== undefined) // Filter out undefined regions
            return Array.from(new Set(regions))
                .sort((a, b) => a[0].localeCompare(b[0])) // Sort the regions by name
                .map((region) => region) // Remove duplicates with incremental id
        }
        return []
    }

    function extractConstituencies(centers?: PollingCenter[] | null): string[] {
        if (centers) {
            const constituencies = centers
                .map((center: PollingCenter) => center.constituency)
                .filter((constituency) => constituency !== undefined)

            return Array.from(new Set(constituencies))
                .sort((a, b) => a[0].localeCompare(b[0])) // Sort by constituency name
                .map((constituency) => constituency)
        }
        return []
    }

    return {
        isLoading: mIsLoading,
        pollingCenters: mPollingCenters,
        regions: mRegions,
        constituencies: mConstituencies,
        getPollingCenters,
        startInterval,
        stopInterval
    }
})