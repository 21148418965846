import { ref, watch, computed } from 'vue'
import { defineStore } from 'pinia'
import L from 'leaflet'
import type { FeatureCollection } from 'geojson'
import {
  defaultPollingStationLayerStyle,
  highlightLayerStyle,
  selectedLayerStyle,
  unknown
} from '@/modules/map/utils/map-layer-styles'

import { trimAndLowercase } from '../../core/utils/string.util'
import MapService from '../services/map.service'
import type { PollingCenter } from '@/modules/polling_station/models/polling-station.model'
import { useVoterStore } from '@/modules/voter/stores/voter.store'
import type { LegendItem } from '../models/legend-item.model'
import PollingStationService from '@/modules/polling_station/services/polling-station.service'

const mapService = new MapService()
const pollingStationService = new PollingStationService()

export const useVoronoiConstituencyMapStore = defineStore('voronoiConstituencyMap', () => {
  const mVoterStore = useVoterStore()

  const mConstituencyGeoJson = ref<FeatureCollection | null>(null)
  const mPollingCentersCount = ref<{ constituency: string, polling_station_count: string | number, polling_station_found_count: string | number, region: string }[]>([])

  const mPollingCenters = ref<PollingCenter[]>([])
  const mSelectedPollingCenters = ref<PollingCenter[]>([])

  const mMap = ref<L.Map | null>(null)
  const mConstituencyBounds = ref<L.LatLngBounds | null | undefined>(null)

  const isLegendVisible = ref<boolean>(true)

  const percentageThreshold = [
    { threshold: 30, color: '#800026' },
    { threshold: 25, color: '#BD0026' },
    { threshold: 15, color: '#E31A1C' },
    { threshold: 12.5, color: '#FC4E2A' },
    { threshold: 10, color: '#FD8D3C' },
    { threshold: 7.5, color: '#FEB24C' },
    { threshold: 1, color: '#FED976' },
    { threshold: 0, color: '#fecc5c' }
  ]

  function resetStore() {
    mSelectedPollingCenters.value = []
    // setActiveLayer()
  }


  const layers = {
    constituency: null as L.GeoJSON | null
  }

  watch(mSelectedPollingCenters, () => {
    updateLayers()
  })

  const mLegendItems = computed(() => {
    const legendItems: LegendItem[] =
      [
        { id: 8, label: '', colorOne: '#FED976', value: '0% - 7.5%' },
        { id: 7, label: '', colorOne: '#FEB24C', value: '7.5% - 10%' },
        { id: 6, label: '', colorOne: '#FD8D3C', value: '10% - 12.5%' },
        { id: 5, label: '', colorOne: '#FC4E2A', value: '12.5% - 15%' },
        { id: 4, label: '', colorOne: '#E31A1C', value: '15% - 20%' },
        { id: 2, label: '', colorOne: '#BD0026', value: '20% - 25%' },
        { id: 1, label: '', colorOne: '#800026', value: '25% - 30% +' }
      ];

    return legendItems;
  })

  const defaultMapView = {
    center: [6.695496799509865, -1.619463456410513],
    zoom: 10
  }

  function initializeMap(mapContainer: HTMLElement) {
    if (mapContainer) {
      mMap.value = L.map(mapContainer, {
        center: defaultMapView.center as L.LatLngExpression,
        zoom: defaultMapView.zoom,
        zoomAnimation: true,
        zoomSnap: 0.25,
        zoomDelta: 0.25,
        wheelDebounceTime: 40
      })

      // Custom icon
      const customIcon = L.icon({
        iconUrl: '/images/pin.png', // Update with your icon path
        iconSize: [38, 38], // size of the icon
        iconAnchor: [22, 38], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -38] // point from which the popup should open relative to the iconAnchor
      });
      for (const station of mPollingCenters.value) {
        if (station.latitude && station.longitude) {
          const marker = L.marker([station.latitude, station.longitude], { icon: customIcon }).addTo(mMap.value as L.Map)
          marker.bindPopup(station.name)
          marker.on('click', () => {
            const { lat, lng } = marker.getLatLng();
            const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
            window.open(googleMapsUrl, '_blank');
          });
        }
      }

    }
  }

  function initializeLayers() {
    if (!mMap.value) return

    layers.constituency = L.geoJSON(mConstituencyGeoJson.value, {
      style: defaultPollingStationLayerStyle,
      onEachFeature: onEachPollingCenter
    })

    setActiveLayer()
  }

  function setActiveLayer() {
    if (!mMap.value) return;

    layers.constituency?.addTo(mMap.value as L.Map);

    if (!mConstituencyBounds.value) {
      mConstituencyBounds.value = (layers.constituency?.getBounds()) ?? null;
      if (mConstituencyBounds.value) {
        mMap.value.fitBounds(mConstituencyBounds.value);
      }
    } else {
      mMap.value.fitBounds(mConstituencyBounds.value);
    }

    updateLayers()

    const refresh = localStorage.getItem('voronoi_refrash') ?? '1';
    
    if (refresh === '1') {
      window.location.reload();
      localStorage.setItem('voronoi_refrash', '0')
    }

  }


  function getFillColor(code: string): string {
    // Get the appropriate election result based on the current level
    const growth = mVoterStore.getRegisteredVotersPollingStation(code);

    if (!growth) {
      console.log('fill_color::else: ', code);
      return unknown; // Return default color if no results are available
    }

    const percentage = growth.percentage;

    if (percentage) {
      return getColor(percentage);
    } else {
      return unknown
    }

  }


  function getColor(percentage: number): string {
    const colors = percentageThreshold;
    return colors.find(c => percentage >= c.threshold)?.color ?? '#fecc5c';
  }


  function updateLayers() {
    if (layers.constituency) {
      layers.constituency.eachLayer((layer: L.Layer) => {
        const layerS = layer as L.Path
        const pollingStationCode = (layer as any).feature.properties.code;
        const pollingStationCodeNormalized = trimAndLowercase(pollingStationCode)

        let fillColor: string | undefined = unknown
        let weight: number = defaultPollingStationLayerStyle.weight

        if (mSelectedPollingCenters.value.length >= 1) {
          mSelectedPollingCenters.value.forEach((c) => {
            if (trimAndLowercase(c.code) === pollingStationCodeNormalized) {
              fillColor = getFillColor(pollingStationCodeNormalized)
              weight = selectedLayerStyle.weight
            }
          })
        } else {
          fillColor = getFillColor(pollingStationCodeNormalized)
          weight = defaultPollingStationLayerStyle.weight
        }

        const currentStyle = layerS.options
        layerS.setStyle({
          ...currentStyle,
          fillColor: fillColor,
          weight: weight
        })
      });
    }

  }

  const fitBounds = () => {
    if (mMap.value && mConstituencyBounds.value) {
      console.log('fir_bounds')
      zoomToFeature(mConstituencyBounds.value)
    }
  }

  function zoomToFeature(bounds: L.LatLngBounds) {
    mMap.value?.flyToBounds(bounds, {
      duration: 0.5, // Duration of animation in seconds
      easeLinearity: 0.25 // Smooth out the animation
    })
  }


  function onEachPollingCenter(feature: any, layer: L.Layer) {
    const pollingStationName = feature.properties.name.trim()

    const layerS = layer as L.Path

    layerS.setStyle({
      ...defaultPollingStationLayerStyle,
    })

    layer.on({
      click: (e) => {
        const clickedLayer = e.target as L.Layer

        const pollingStationNameNormalized = trimAndLowercase(pollingStationName)

        const constituency = mPollingCenters.value.find(
          (c) => trimAndLowercase(c.name) === pollingStationNameNormalized
        )

        if (constituency) {
          const constituencyAlreadySelected = mSelectedPollingCenters.value.some(
            (c) => trimAndLowercase(c.name) === pollingStationNameNormalized
          )

          if (!constituencyAlreadySelected) {
            mSelectedPollingCenters.value = [...mSelectedPollingCenters.value, constituency]
          } else {
            mSelectedPollingCenters.value = mSelectedPollingCenters.value.filter(
              (c) => trimAndLowercase(c.name) !== pollingStationNameNormalized
            )
          }
        }
        // zoomToFeature(e.target.getBounds())

      },
      mouseover: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        if (!mSelectedPollingCenters.value.includes(pollingStationName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            weight: 4,
            color: "#666",
            fillOpacity: highlightLayerStyle.fillOpacity
          }
          layer.setStyle(newStyle)
        }

        layer.bindTooltip(
          `<h5>${pollingStationName.toUpperCase()}</h5>`,
          { direction: 'top' }
        ).openTooltip()
      },
      mouseout: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        if (!mSelectedPollingCenters.value.includes(pollingStationName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            weight: defaultPollingStationLayerStyle.weight,
            color: defaultPollingStationLayerStyle.color,
            fillOpacity: defaultPollingStationLayerStyle.fillOpacity
          }
          layer.setStyle(newStyle)
        }
      }
    })
  }

  function toggleLegend() {
    isLegendVisible.value = !isLegendVisible.value
  }

  const pollingStationsCountSummary = (constituency: string) =>
    computed(() => {
      const stations = mPollingCentersCount.value.filter((station) =>
        trimAndLowercase(station.constituency) === trimAndLowercase(constituency)
      )

      const summary = stations.reduce((acc, s) => {
        const count = s.polling_station_count ?? 0
        const foundCount = s.polling_station_found_count ?? 0

        return {
          ...acc,
          count: Number(acc.count) + Number(count),
          foundCount: Number(acc.foundCount) + Number(foundCount)
        }
      }, {
        count: 0,
        foundCount: 0
      })

      return `${summary.foundCount} of ${summary.count} Polling Stations Found`
    })

  const getPollingCentersCount = async (region: string) => {
    const result = await pollingStationService.getPollingStationsCount(region)

    result.fold(
      (value) => {
        mPollingCentersCount.value = value as any[]
      },
      (error) => console.log(error)
    )
  }

  const getConstituencyByRegionShapeFile = async (region: string, constituency: string) => {
    const result = await mapService.getPollingStationShapeFile(region, constituency)

    result.fold(
      (value) => {
        mConstituencyGeoJson.value = value as FeatureCollection
        console.log('constituency shape file set')
        const pollingStations = extractPollingStations(mConstituencyGeoJson.value)
        mPollingCenters.value = pollingStations
      },
      (error) => console.log(error)
    )
  }

  function extractPollingStations(geoJson?: FeatureCollection | null): PollingCenter[] {
    if (geoJson) {
      const pollingStations = geoJson.features
        .map((feature: any) => [
          feature.properties?.code.trim(),
          feature.properties?.name.trim(),
          feature.properties?.latitude,
          feature.properties?.longitude,
        ])
        .filter((region: any) => region !== undefined) // Filter out undefined regions
      return Array.from(new Set(pollingStations))
        .sort((a, b) => a[0].localeCompare(b[0])) // Sort the regions by name
        .map(([code, name, latitude, longitude]) => ({ code: code, name: name, latitude: latitude, longitude: longitude })) // Remove duplicates with incremental id
    }
    return []
  }


  return {
    pollingCenters: mPollingCenters,
    selectedPollingCenters: mSelectedPollingCenters,
    isLegendVisible,
    legendItems: mLegendItems,
    pollingCentersCount: mPollingCentersCount,
    pollingStationsCountSummary,
    getConstituencyByRegionShapeFile,
    getPollingCentersCount,
    fitBounds,
    initializeMap,
    initializeLayers,
    // setActiveLayer,
    toggleLegend,
    resetStore
  }
})
