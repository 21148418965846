import { defineStore } from 'pinia';
import { ref } from 'vue';
import { messaging } from '../../../firebase-config'; // Adjust the path as necessary
import { getToken, onMessage } from 'firebase/messaging';
import { useToast } from "primevue/usetoast";
import { useAppUserStore } from '@/modules/app-user/stores/app-user.store';
import * as AppConstants from '@/modules/core/constants/app.constants'
import type Message from '../models/message.class';


export const useFirebaseStore = defineStore('firebase', () => {
  const FIREBASE_TOKEN = `${AppConstants.DOMAIN}.firebase_token`;
  const firebaseToken = localStorage.getItem(FIREBASE_TOKEN);
  const messages = ref<Message[]>([]);
  const toast = useToast();

  const setToken = async () => {
    try {
      const currentToken = await getToken(messaging, { vapidKey: 'BOdcZFkGccIczFYqaIPWzdgBJyLG0B_yvHn9y4183jwCydf7RSijlEAp577vMCevtBmTKNQ2s4cCUjPYEzBbbog' });
      if (currentToken) {
        useAppUserStore().updateFmcToken(currentToken);
        localStorage.setItem(FIREBASE_TOKEN, currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      console.error('An error occurred while retrieving token. ', err);
    }

  };

  const removeToken = () => localStorage.removeItem(FIREBASE_TOKEN);

  const receiveMessage = () => {
    onMessage(messaging, (payload) => {
      const from = payload?.from;
      const messageId = payload?.messageId;
      const notificationTitle = payload?.notification?.title ?? '';
      const notificationBody = payload?.notification?.body ?? '';

      const msg: Message = {
        from: from,
        messageId: messageId,
        title: notificationTitle,
        body: notificationBody,
      }

      messages.value.push(msg);

      console.log('message: ', msg)
      toast.add({
        severity: 'info',
        summary: notificationTitle,
        detail: notificationBody,
        life: 3000
      });

    });
  };

  return {
    firebaseToken,
    setToken,
    removeToken,
    receiveMessage,
  };
})
