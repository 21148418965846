import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import type Voters from '../models/voters.model'
import type Voter from '../models/voter.model'
import type { Constituency, ConstituencyRegisteredVoter, VoterCount } from '../models/registered-voters'
import { VoterType } from '../enums/voter-type.enum'

import VoterService from '../services/voter.service'

import { trimAndLowercase } from '@/modules/core/utils/string.util'
import { useVoterMapStore } from '@/modules/map/stores/voter-map.store'
import { useVoronoiConstituencyMapStore } from '@/modules/map/stores/voronoi-constituency-map.store'

const voterService = new VoterService()

export const useVoterStore = defineStore('voter', () => {
  const mIsLoading = ref<boolean>(false)

  const mVoterMapStore = useVoterMapStore()
  const mConstituencyMapStore = useVoronoiConstituencyMapStore()

  const mVoter = ref<Voter[]>([])
  const mSelectedVoter = ref<Voter | null>(null)
  const mVoterCounts = ref<VoterCount[]>([])

  const mConstituencyRegisteredVoter = ref<ConstituencyRegisteredVoter[]>([])

  const mAgeGroups = ref<string[]>([
    '18_21',
    '22_30',
    '31_40',
    '41_50',
    '51_60',
    '61_70',
    '71_80',
    '80_plus'
  ])

  function resetStore() {
    mSelectedVoter.value = mVoter.value[0] as Voter
  }

  const formatAgeGroup = (ageGroup: string): string => {
    if (ageGroup === '80_plus') return '80+'
    return ageGroup.replace('_', '-')
  }

  function filterVotersBy(key: 'region' | 'constituency', value: string) {
    if (!mSelectedVoter.value) return []
    return mSelectedVoter.value.voters
      .filter((voter) => voter[key] !== undefined)
      .filter((voter) => voter[key].trim().toLowerCase() === value.trim().toLowerCase())
  }

  function aggregateAgeGroup(filteredVoters: Voters[], key: string) {
    return filteredVoters.reduce(
      (acc: any, curr: any) => {
        Object.keys(acc).forEach((ageGroup) => {
          acc[ageGroup] += curr[key][ageGroup] || 0
        })
        return acc
      },
      {
        '18_21': 0,
        '22_30': 0,
        '31_40': 0,
        '41_50': 0,
        '51_60': 0,
        '61_70': 0,
        '71_80': 0,
        '80_plus': 0
      }
    )
  }

  function calculateTotals(filteredVoters: Voters[]) {
    const totalVoters = filteredVoters.reduce((acc, curr) => acc + curr.totalVoters, 0)
    const totalVotersMale = filteredVoters.reduce((acc, curr) => acc + curr.totalVotersMale, 0)
    const totalVotersFemale = filteredVoters.reduce((acc, curr) => acc + curr.totalVotersFemale, 0)

    return { totalVoters, totalVotersMale, totalVotersFemale }
  }

  function getVotersData(filteredVoters: Voters[]) {
    const { totalVoters, totalVotersMale, totalVotersFemale } = calculateTotals(filteredVoters)

    const votersAgeGroup = aggregateAgeGroup(filteredVoters, 'voters')
    const votersMaleAgeGroup = aggregateAgeGroup(filteredVoters, 'votersMale')
    const votersFemaleAgeGroup = aggregateAgeGroup(filteredVoters, 'votersFemale')

    return {
      region: '',
      constituency: '',
      totalVoters: totalVoters,
      totalVotersMale: totalVotersMale,
      totalVotersFemale: totalVotersFemale,
      voters: votersAgeGroup,
      votersMale: votersMaleAgeGroup,
      votersFemale: votersFemaleAgeGroup
    } as Voters
  }

  function getVotersRegion(region: string) {
    const filteredVoters = filterVotersBy('region', region)
    return getVotersData(filteredVoters)
  }

  function getVotersConstituency(constituency: string) {
    const filteredVoters = filterVotersBy('constituency', constituency)
    return getVotersData(filteredVoters)
  }


  function getTotalVotersCount(data: VoterCount[], year: 2020 | 2024): number {
    return data.reduce((total, region) =>
      total + region[`voter_count_${year}`], 0
    );
  }

  function getTotalRatio(data: VoterCount[], year: 2020 | 2024): number {
    return data.reduce((total, region) =>
      total + region[`ratio_${year}`], 0
    );
  }


  function getRegisteredVotersRegion(region: string, year: 2020 | 2024, isTenRegions: boolean = false) {
    const regionNames = isTenRegions ? getRegionAliases(region) : [region];

    const voteCounts = mVoterCounts.value.filter(
      (item) => item.region && regionNames.some(
        (reg) => trimAndLowercase(item.region) === trimAndLowercase(reg)
      )
    ) || [];

    if (!voteCounts || voteCounts.length < 1) return 0

    const voters2020 = getTotalVotersCount(voteCounts, 2020);
    const voters2024 = getTotalVotersCount(voteCounts, 2024);

    const ratio = getTotalRatio(voteCounts, year);

    const netGrowth = voters2024 - voters2020
    const percentageGrowth = ((voters2024 - voters2020) / voters2020) * 100

    return {
      net: netGrowth,
      percentage: percentageGrowth,
      ratio: ratio,
    }
  }

  function getRegionAliases(region: string): string[] {
    switch (trimAndLowercase(region)) {
      case 'northern':
        return ['northern', 'north east', 'savannah'];
      case 'brong ahafo':
        return ['bono', 'bono east', 'ahafo'];
      case 'volta':
        return ['volta', 'oti'];
      case 'western':
        return ['western', 'western north'];
      default:
        return [region];
    }
  }

  function calculateNetGrowth(constituencies: Constituency[] | ConstituencyRegisteredVoter[]): number {
    return constituencies.reduce((total, c) => total + (c.voter_count_2024 - c.voter_count_2020), 0);
  }

  function calculatePercentageGrowth(constituencies: Constituency): number {
    const totalVoters2020 = constituencies.voter_count_2020 ?? 0;
    const totalVoters2024 = constituencies.voter_count_2024 ?? 0;
    return ((totalVoters2024 - totalVoters2024) / totalVoters2020) * 100;
  }

  function getRegisteredVotersConstituency(constituency: string, year: 2020 | 2024) {
    const filteredResults = mVoterCounts.value.map(region => ({
      ...region,
      constituencies: region.constituencies.filter(c => trimAndLowercase(c.constituency) === trimAndLowercase(constituency))
    })).filter(region => region.constituencies.length > 0) || [];

    if (!filteredResults || filteredResults.length < 1) return 0

    const allConstituencies = filteredResults.flatMap(region => region.constituencies);

    return {
      net: calculateNetGrowth(allConstituencies),
      percentage: allConstituencies[0].percentage,
      ratio: ((year === 2020 ? allConstituencies[0].ratio_2020 : allConstituencies[0].ratio_2024))
    };
  }

  function getRegisteredVotersPollingStation(pollingStation: string) {
    const filteredResults = mConstituencyRegisteredVoter.value.map(constituency => ({
      ...constituency,
      polling_stations: constituency.polling_stations.filter(s =>
        trimAndLowercase(s.code) === trimAndLowercase(pollingStation)
      )
    })).filter(constituency => constituency.polling_stations.length > 0) || [];

    if (!filteredResults || filteredResults.length < 1) return 0

    const allPollingStations = filteredResults.flatMap(constituency => constituency.polling_stations);

    const percentage = allPollingStations[0].percentage;
    return {
      net: 0,
      percentage: ((!percentage) ? 0 : percentage) * 100
    };
  }

  function getSelectedConstituencies() {
    return mVoterMapStore.selectedConstituencies.length < 1 ? mVoterMapStore.filteredConstituencies : mVoterMapStore.selectedConstituencies;
  }

  function getSelectedPollingStations() {
    return mConstituencyMapStore.selectedPollingCenters.length < 1 ? mConstituencyMapStore.pollingCenters : mConstituencyMapStore.selectedPollingCenters;
  }

  const mRegisteredVotersSummary = () =>
    computed(() => {
      const constituencies = getSelectedConstituencies().map(c => trimAndLowercase(c.name))
      const voterCounts = mVoterCounts.value
      if (!voterCounts) return {
        previousYearRegisteredVoters: 0,
        currentYearRegisteredVoters: 0,
        net: 0,
        percentage: 0
      }

      const filteredResults = constituencies.length < 1
        ? voterCounts
        : voterCounts.map(region => ({
          ...region,
          constituencies: region.constituencies.filter(c => constituencies.includes(trimAndLowercase(c.constituency)))
        })).filter(region => region.constituencies.length > 0);

      const allConstituencies = filteredResults.flatMap(region => region.constituencies);

      const summary = allConstituencies.reduce((acc, c) => {
        const previousYearRegisteredVoters = c.voter_count_2020 ?? 0
        const currentYearRegisteredVoters = c.voter_count_2024 ?? 0

        return {
          ...acc,
          previousYearRegisteredVoters: acc.previousYearRegisteredVoters + previousYearRegisteredVoters,
          currentYearRegisteredVoters: acc.currentYearRegisteredVoters + currentYearRegisteredVoters
        }
      }, {
        previousYearRegisteredVoters: 0,
        currentYearRegisteredVoters: 0
      })

      const netGrowth = ((summary.currentYearRegisteredVoters - summary.previousYearRegisteredVoters))
      const percentageGrowth = ((summary.currentYearRegisteredVoters - summary.previousYearRegisteredVoters) / summary.previousYearRegisteredVoters)

      return {
        ...summary,
        net: netGrowth,
        percentage: (!isFinite(percentageGrowth) ? 0 : percentageGrowth) * 100
      }
    })

  const mPollingStationRegisteredVotersSummary = () =>
    computed(() => {
      const pollingStationCodes = getSelectedPollingStations().map(p => trimAndLowercase(p.code))
      const registeredVoters = mConstituencyRegisteredVoter.value
      if (registeredVoters.length < 1) return {
        previousYearRegisteredVoters: 0,
        currentYearRegisteredVoters: 0,
        net: 0,
        percentage: 0
      }

      const filteredResults = pollingStationCodes.length < 1
        ? registeredVoters
        : registeredVoters.map(constituency => ({
          ...constituency,
          polling_stations: constituency.polling_stations.filter(s =>
            pollingStationCodes.includes(trimAndLowercase(s.code))
          )
        })).filter(constituency => constituency.polling_stations.length > 0);

      const allPollingStations = filteredResults.flatMap(constituency => constituency.polling_stations);

      const summary = allPollingStations.reduce((acc, c) => {
        const previousYearRegisteredVoters = c.voter_count_2020 ?? 0
        const currentYearRegisteredVoters = c.voter_count_2024 ?? 0

        return {
          ...acc,
          previousYearRegisteredVoters: acc.previousYearRegisteredVoters + previousYearRegisteredVoters,
          currentYearRegisteredVoters: acc.currentYearRegisteredVoters + currentYearRegisteredVoters
        }
      }, {
        previousYearRegisteredVoters: 1,
        currentYearRegisteredVoters: 1
      })

      const netGrowth = ((summary.currentYearRegisteredVoters - summary.previousYearRegisteredVoters))
      const percentageGrowth = ((summary.currentYearRegisteredVoters - summary.previousYearRegisteredVoters) / summary.previousYearRegisteredVoters)

      return {
        ...summary,
        net: netGrowth,
        percentage: (!isFinite(percentageGrowth) ? 0 : percentageGrowth) * 100
      }
    })

  const formatPercentage = (value: number): string => {
    return `${(value * 100).toFixed(2)}%`
  }
  const getVoters = async () => {
    mIsLoading.value = true

    const result = await voterService.getVoters()

    result.fold(
      (value) => {
        mIsLoading.value = false
        mVoter.value = value as Voter[]
        mSelectedVoter.value = mVoter.value[0]
      },
      (error) => console.log(error)
    )
  }

  const getVoterCounts = async () => {
    mIsLoading.value = true

    const result = await voterService.getVoterCounts()

    result.fold(
      (value) => {
        mIsLoading.value = false
        mVoterCounts.value = value as VoterCount[]
      },
      (error) => console.log(error)
    )
  }

  const getRegionRegisteredVoters = async () => {
    const result = await voterService.getRegionRegisteredVoters()

    result.fold(
      (value) => {
        mConstituencyRegisteredVoter.value = value as ConstituencyRegisteredVoter[]
      },
      (error) => console.log(error)
    )
  }
  return {
    ageGroups: mAgeGroups,
    voter: mVoter,
    selectedVoter: mSelectedVoter,
    registeredVotersSummary: mRegisteredVotersSummary,
    pollingStationRegisteredVotersSummary: mPollingStationRegisteredVotersSummary,
    formatAgeGroup,
    getVotersData,
    getVoters,
    getVoterCounts,
    getRegionRegisteredVoters,
    getVotersRegion,
    getVotersConstituency,
    getRegisteredVotersRegion,
    getRegisteredVotersConstituency,
    getRegisteredVotersPollingStation,
    resetStore
  }
})
