<script setup lang="ts">
import { useRouter } from 'vue-router';

const router = useRouter();
interface Props {
    title: string;
}
const props = defineProps<Props>()

const navigateHome = () => {
    router.push({ name: 'home' });
    localStorage.setItem('voronoi_refrash', '1')
};
</script>

<template>
    <div class="centered-text">
        <div :style="{ fontSize: '1.5rem', textAlign: 'center', fontWeight: 'bold' }"> {{ props.title }} </div>
        <!-- <router-link :to="{ name: 'home' }"> -->
        <div :style="{ fontSize: '1.2rem', textAlign: 'center', color: 'blue' }" @click="navigateHome"> Return Home </div>
        <!-- </router-link> -->
    </div>
</template>

<style scoped>
.centered-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 0.54rem;
    padding: 0.2rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin: 0.5rem auto;
    border: 1px solid #ccc;
}
</style>