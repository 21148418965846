const AuthModule = () => import('./AuthModule.vue')
const LoginForm = () => import('./views/LoginForm.vue')

export const authModuleRoute = {
  name: 'auth',
  path: '/auth',
  component: AuthModule,
  children: [
    {
      path: 'login',
      name: 'auth.login',
      component: LoginForm
    },

  ]
}
