const PollingCenterModule = () => import('./PollingCenterModule.vue')
import PollingCenterIndex from '@/modules/polling_station/views/PollingCenterIndex.vue'

export const pollingStationModuleRoute = {
  name: 'polling-center',
  path: '/polling-center',
  component: PollingCenterModule,
  children: [
    {
      path: 'polling-centers',
      name: 'polling-centers.index',
      component: PollingCenterIndex
    }
  ]
}
