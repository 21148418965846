import Result from '@/modules/core/models/result.class'
import axios from 'axios'
import { mapVoterFromVoterDTO } from '../mappers/voter.mapper'

import years from '@/assets/voter/years.json'
import type VoterDto from '../dtos/voter.dto'
import type { ConstituencyRegisteredVoter, VoterCount } from '../models/registered-voters'

export default class VoterService {
  async getVoters() {
    try {
      const responses = await Promise.all(
        years.map((e: string) => {
          return axios.get<VoterDto>(`/data/voters/${e}.json`)
        })
      )
      const voters = []
      for (const response of responses) {
        const voter = response.data
        voters.push(voter)
      }

      const mappedVoters = voters.map((dto) => mapVoterFromVoterDTO(dto))

      return Result.success(mappedVoters)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }

  async getVoterCounts() {
    try {
      const response = await axios.get<VoterCount[]>('/data/voters/voter_count.json')
      const voterCounts = response.data
      return Result.success(voterCounts)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }

  async getRegionRegisteredVoters() {
    try {
      const response = await axios.get<ConstituencyRegisteredVoter[]>('/data/voters/ashanti_registered_voters.json')
      const constituencyRegisteredVoters = response.data
      return Result.success(constituencyRegisteredVoters)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }

}
