<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useLayout } from '@/layout/composables/layout'
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth.store';
import { useAppUserStore } from '@/modules/app-user/stores/app-user.store';

const { layoutConfig, onMenuToggle } = useLayout()

const outsideClickListener = ref(null)
const topbarMenuActive = ref(false)
const router = useRouter()
const visibleRight = ref(false);
const authStore = useAuthStore();
const appUserStore = useAppUserStore();

onMounted(() => {
  bindOutsideClickListener()
})

onBeforeUnmount(() => {
  unbindOutsideClickListener()
})

const firstUppercase = computed(() => {
  if (!appUserStore.appUse) {
    return ''; // Return empty string for an empty input
  }

  const firstLetter = appUserStore.appUser.name.charAt(0).toUpperCase();
  return firstLetter;
})

const logoUrl = computed(() => {
  return `/layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`
})

const dashboardRouteName = computed(() => {
  if (appUserStore.isAppUserRegulator) {
    return 'regulator.dashboard'
  } else if (appUserStore.isAppUserCompany) {
    return 'company.dashboard'
  } else {
    return ''
  }
})

const dashboardRouteParams = computed(() => {
  if (appUserStore.isAppUserRegulator) {
    return { regulatorId: appUserStore.appUser.regulator.id }
  } else if (appUserStore.isAppUserCompany) {
    return { companyId: appUserStore.appUser.company.id }
  } else {
    return {}
  }
})

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value
}
const onSettingsClick = () => {
  topbarMenuActive.value = false
  router.push('/documentation')
}
const topbarMenuClasses = computed(() => {
  return {
    'layout-topbar-menu-mobile-active': topbarMenuActive.value
  }
})

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false
      }
    }
    document.addEventListener('click', outsideClickListener.value)
  }
}

const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener)
    outsideClickListener.value = null
  }
}

const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return

  const sidebarEl = document.querySelector('.layout-topbar-menu')
  const topbarEl = document.querySelector('.layout-topbar-menu-button')

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  )
}
</script>

<template>
  <div class="layout-topbar">
    <router-link :to="{ name: 'home' }" class="layout-topbar-logo">
      <img :src="logoUrl" alt="logo" />
      <span style="color:rgb(0, 67, 29)">Open </span>
      <span style="color:rgb(247, 148, 30)"> Insight</span>
    </router-link>


    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <button @click="visibleRight = true" class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span>Profile</span>
      </button>
    </div>
    <Sidebar v-model:visible="visibleRight" header="Profile" position="right">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <div class="flex-auto">
            <Avatar :label="firstUppercase" class="mr-2" size="large"
              style="background-color: rgb(45, 187, 84); color: #ffffff" />
          </div>
        </div>
        <div class="field col-12 md:col-8">
          <div class="flex-auto">
          </div>
        </div>
        <div class="field col-12 md:col-6">
        </div>
        <div class="field col-12 md:col-6">
          <Button label="Logout" icon="pi pi-sign-out" severity="danger" outlined @click="authStore.logout" />
        </div>

      </div>
    </Sidebar>
  </div>
</template>

<style lang="scss" scoped></style>
