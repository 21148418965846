type LogLevel = 'log' | 'info' | 'warn' | 'error';

const logMessage = (level: LogLevel, prefix: string, ...data: any[]) => {
    if (import.meta.env.DEV) {
        console[level](prefix, ...data);
    }
};

export const log = (...data: any[]) => logMessage('log', '', ...data);
export const info = (...data: any[]) => logMessage('info', 'ℹ️ Info ℹ️:', ...data);
export const warn = (...data: any[]) => logMessage('warn', '⚠️ Warning ⚠️:', ...data);
export const error = (...data: any[]) => logMessage('error', '🔥 Error 🔥:', ...data);
