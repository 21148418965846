import { mapMetaFromMetaDTO } from "@/modules/core/mappers/meta.mappers";
import type { SlipDto, SlipsDto } from "../dtos/slip.dto";
import type { Slip, Slips } from "../models/slip.model";

export function mapSlipsFromSlipsDTO(dto?: SlipsDto | null): Slips | null {
    return !dto
        ? null
        : {
            data: dto.data.map((dto) => mapSlipFromSlipDTO(dto)),
            meta: mapMetaFromMetaDTO(dto.meta)
        } as Slips
}

export function mapSlipFromSlipDTO(dto?: SlipDto | null): Slip | null {
    return !dto
        ? null
        : {
            id: dto.id,
            serialNumber: dto.serial_number,
            pollingStationCode: dto.polling_station_code,
            pollingStation: dto.polling_station_name,
            constituency: dto.constituency_name,
            region: dto.region_name,
            npp: dto.npp,
            ndc: dto.ndc,
            gum: dto.gum,
            cpp: dto.cpp,
            gfp: dto.gfp,
            gcpp: dto.gcpp,
            apc: dto.apc,
            lpg: dto.lpg,
            pnc: dto.pnc,
            ppp: dto.ppp,
            ndp: dto.ndp,
            independent: dto.independent,
            totalBallotsIssuedA1: dto.total_number_of_ballots_issued_a1,
            registerVotersB1: dto.number_of_voters_on_register_b1,
            ballotsIssuedVotersC1: dto.number_of_ballots_issued_to_voters_c1,
            rejectedBallotD1: dto.rejected_ballot_d1,
            spoiltBallotC3: dto.spoilt_ballot_c3,
            totalVotesCastRecordedSnc: dto.total_votes_cast_recorded_snc,
            totalValidBallotsRecordedSna: dto.total_valid_ballots_recorded_sna,
            biometricVerificationDevice: dto.biometric_verification_device,
            manualVerification: dto.manual_verification,
            pinkSheetStampStatus: dto.pink_sheet_stamp,
            voterName: dto.voter_name,
            date: dto.date,
            time: dto.time,
            isVerified: dto.is_verified,
            imageUrl: dto.image_url,
            createdAt: dto.created_at,
            link: dto.link,
        } as Slip
}
