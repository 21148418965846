import { openInsightApi } from '@/modules/core/services/api-openinsight.service'
import Result from '@/modules/core/models/result.class'

import * as ApiConstants from '../constants/auth-api-constants'
import * as AuthConstants from '../constants/auth-constants'
import type LoginResponseDTO from '../dtos/login-response.dto'
import type { Login } from '../models/auth.model'
import type AccessTokenDTO from '../dtos/access-token.dto'
import { mapLoginResponseFromLoginResponseDTO } from '../mappers/auth.mapper'
import { collection, addDoc } from 'firebase/firestore'
import { db } from '@/firebase-config'
import { log } from '@/modules/core/utils/console.util'
import axios from 'axios'

export default class AuthApiService {
  async login(login: Login) {
    try {

      console.log('AuthApiService::login')
      if (AuthConstants.PINS.includes(login.code)) {
        const tokenResponse = await axios.post<AccessTokenDTO>(
          "https://api.openinsight.app/oauth/token",
          {
            grant_type: ApiConstants.GRANT_TYPE,
            client_id: ApiConstants.CLIENT_ID,
            client_secret: ApiConstants.CLIENT_SECRET,
          }
        )

        const docRef = await addDoc(collection(db, 'logins'), login)
        log('Document written with ID: ', docRef.id)

        if (tokenResponse.status === 200) {
          const accessToken = tokenResponse.data.access_token
          const tokenType = tokenResponse.data.token_type
          const loginResponse = {
            user: {
              user_type: "",
              user_id: 0,
              id: 0,
              name: "",
              phone_number: "",
            },
            access_token: {
              access_token: accessToken,
              token_type: tokenType,
              expires_in: new Date(Date.now() + (24 * 60 * 60 * 1000)).toISOString()
            }

          } as LoginResponseDTO

          return Result.success(mapLoginResponseFromLoginResponseDTO(loginResponse))

        } else {
          return Result.failure(new Error('An unknown OAuth error occurred. Please try again.'))
        }

      } else {
        return Result.failure(new Error('Invalid PIN. Please try again.'))
      }
      /*
            const response = await openInsightApi.post<ObjectResponse<LoginResponseDTO>>(
              ApiConstants.LOGIN_ENDPOINT,
              dto
            )
      
            if (response.data.successful) {
              return Result.success(mapLoginResponseFromLoginResponseDTO(response.data.data!))
            } else {
              return Result.failure(new Error(response.data.message || 'An unknown error occurred'))
            }
      */
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      return Result.failure(error)
    }
  }
}
