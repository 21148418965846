import Result from '@/modules/core/models/result.class'
import axios from 'axios'
import type ConstituencyShape from '../../data/models/constituency_shape.model'

import regions from '@/assets/voter/regions.json'

export default class MapService {
  async getRegionShapeFile() {
    try {
      const response = await axios.get(`/data/geojson/regions_10.json`)

      const regionsGeoJson = response.data

      return Result.success(regionsGeoJson)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }

  async getRegionOldShapeFile() {
    try {
      const response = await axios.get(`/data/geojson/regions_16.json`)

      const regionsGeoJson = response.data

      return Result.success(regionsGeoJson)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }

  async getConstituencyShapeFile() {
    try {
      const response = await axios.get(`/data/geojson/constituencies_276.json`)

      const constituencyGeoJson = response.data

      return Result.success(constituencyGeoJson)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }
  async getPollingStationShapeFile(region: string, constituency: string) {
    try {
      const response = await axios.get(`/data/geojson/voronoi/${region}/${constituency}.geojson`)

      const constituencyGeoJson = response.data

      return Result.success(constituencyGeoJson)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }

  async getConstituenciesShapeFile() {
    try {
      const responses = await Promise.all(
        regions.map((e: string) => {
          return axios.get<ConstituencyShape>(`/data/geojson/regions/${e}.geojson`)
        })
      )
      const constituenciesShape = []
      for (const response of responses) {
        const election = response.data

        constituenciesShape.push(election)
      }

      return Result.success(constituenciesShape)
    } catch (e) {
      const error = e instanceof Error ? e : new Error('An unknown error occurred')
      console.log(error)
      return Result.failure(error)
    }
  }
}
