import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMapUiStore = defineStore('map_ui', () => {

    const mActiveTab = ref(0);
    const isSidebarCollapsed = ref(false);

    function resetStore() {
        mActiveTab.value = 0
        isSidebarCollapsed.value = false
    }

    const toggleSidebar = () => {
        isSidebarCollapsed.value = !isSidebarCollapsed.value;
    };


    return {
        activeTab: mActiveTab,
        isSidebarCollapsed,
        toggleSidebar,
        resetStore
    }

});