<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import 'leaflet/dist/leaflet.css'

import { LayerLevel } from '@/modules/map/enums/layer-level.enum'

import ElectoralCard from '@/modules/election/components/ElectoralCard.vue'

import { useDataStore } from '../../data/stores/data.store'
import { useMapUiStore } from '../stores/ui.store'
import { usePredictionMapStore } from '../stores/prediction-map.store'


const mapContainer = ref<HTMLElement | null>(null)
const dataStore = useDataStore()

const mapUiStore = useMapUiStore()
const predictionStore = usePredictionMapStore()

const showRegionNewButton = computed(() => predictionStore.currentLevel !== LayerLevel.REGION_SIXTEEN)
const showRegionOldButton = computed(() => predictionStore.currentLevel !== LayerLevel.REGION_TEN)

const nppPartyStyle = computed(() => ({
  color: "#01009C"
}))

const ndcPartyStyle = computed(() => ({
  color: "#175D39"
}))

const showConstituencyButton = computed(
  () =>
    predictionStore.currentLevel !== LayerLevel.CONSTITUENCY &&
    predictionStore.currentLevel !== LayerLevel.REGION_SIXTEEN_CONSTITUENCY
)

function resetStore() {
  mapUiStore.resetStore()
  predictionStore.resetStore()
}


onMounted(async () => {
  await Promise.all([
    dataStore.getShapeFiles(),
  ]);
  if (mapContainer.value) {
    predictionStore.initializeMap(mapContainer.value)
    predictionStore.initializeLayers()
  }
})
</script>

<template>
  <div class="parent">
    <div class="presidential-container">
      <router-link :to="{ name: 'home' }">
        <Button outlined :style="{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }">Home</Button>
      </router-link>

      <div class="presidential">
        <div class="map-statistics">
          <TabView class="tab-view" v-model:activeIndex="mapUiStore.activeTab">
            <TabPanel header="Results">
              <div class="top-content">
                <div class="election-results">
                  <h1 class="title">2024 PRESIDENTIAL RESULTS(WHAT IF)</h1>
                  <div class="results-bar">
                    <!-- Candidate Divs -->
                    <div class="candidates">
                      <div class="candidate first-leading-party" :style="nppPartyStyle">
                        <img src="/images/party/npp.jpg" class="candidate-image" />
                        <div class="candidate-info">
                          <span class="electoral-votes">NPP: {{
                            predictionStore.constituenciesNppCount().value.toLocaleString() }}</span>
                        </div>
                      </div>
                      <div class="candidate second-leading-party" :style="ndcPartyStyle">
                        <div class="candidate-info">
                          <span class="electoral-votes"> NDC: {{
                            predictionStore.constituenciesNdcCount().value.toLocaleString() }}</span>
                          3
                        </div>
                        <img src="/images/party/ndc.png" alt="Party" class="candidate-image" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </TabPanel>
            <TabPanel header="QWERTY">
              <div class="top-content">
                <ElectoralCard name="Joe Biden" party="Democratic" :electoral-votes="306" :popular-votes="75344463"
                  image-url="/images/presidential/npp.jpg" />

              </div>
            </TabPanel>
          </TabView>
        </div>

        <div class="map-content">
          <div class="map-controls">
            <Dropdown v-model="predictionStore.selectedPredictionParty" :options="predictionStore.predictionParties"
              placeholder="Select Party" checkmark :highlightOnSelect="true" />

            <MultiSelect v-if="predictionStore.currentLevel === LayerLevel.REGION_SIXTEEN_CONSTITUENCY"
              v-model="predictionStore.selectedSixteenRegions" :options="dataStore.sixteenRegions" filter
              optionLabel="name" placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    predictionStore.selectedSixteenRegions ? predictionStore.selectedSixteenRegions.length : 0
                  }}</b>
                  item{{
                    (predictionStore.selectedSixteenRegions ? predictionStore.selectedSixteenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="predictionStore.currentLevel === LayerLevel.REGION_TEN_CONSTITUENCY"
              v-model="predictionStore.selectedTenRegions" :options="dataStore.tenRegions" filter optionLabel="name"
              placeholder="Select Regions" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    predictionStore.selectedTenRegions ? predictionStore.selectedTenRegions.length : 0
                  }}</b>
                  item{{
                    (predictionStore.selectedTenRegions ? predictionStore.selectedTenRegions.length : 0) >
                      1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>
            <MultiSelect v-if="predictionStore.currentLevel === LayerLevel.CONSTITUENCY"
              v-model="predictionStore.selectedConstituencies" :options="dataStore.constituencies" filter
              optionLabel="name" placeholder="Select Constituencies" :maxSelectedLabels="2" class="w-full md:w-18rem">
              <template #footer>
                <div class="py-2 px-3">
                  <b>{{
                    predictionStore.selectedConstituencies
                      ? predictionStore.selectedConstituencies.length
                      : 0
                  }}</b>
                  item{{
                    (predictionStore.selectedConstituencies
                      ? predictionStore.selectedConstituencies.length
                      : 0) > 1
                      ? 's'
                      : ''
                  }}
                  selected.
                </div>
              </template>
            </MultiSelect>

            <Button class="control-button" v-if="showRegionNewButton"
              @click="predictionStore.setActiveLayer(LayerLevel.REGION_SIXTEEN)" text>
              16 Regions
            </Button>
            <Button class="control-button" v-if="showRegionOldButton"
              @click="predictionStore.setActiveLayer(LayerLevel.REGION_TEN)" text>
              10 Regions
            </Button>
            <Button class="control-button" v-if="showConstituencyButton"
              @click="predictionStore.setActiveLayer(LayerLevel.CONSTITUENCY)" text>
              Constituencies
            </Button>

            <Button class="control-button" @click="predictionStore.fitBounds" text>Resize Map</Button>
            <Button class="control-button" @click="resetStore" text>Reset</Button>
          </div>

          <div ref="mapContainer" class="map-container"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped>
/* Apply styles with higher specificity and use !important */
#mapContainer .leaflet-control-zoom-in,
#mapContainer .leaflet-control-zoom-out {
  width: 60px !important;
  height: 60px !important;
  font-size: 24px !important;
  line-height: 36px !important;
  padding: 4px !important;
}

#mapContainer .leaflet-control-zoom {
  width: 50px !important;
}

#mapContainer .leaflet-control-attribution .a {
  font-size: 0px !important;
  /* background-color: rgba(255, 255, 255, 0.7); */
}

.parent {
  display: flex;
  height: 88vh;
  /* Adjusted for 3% top and bottom margins */
  width: 92%;
  /* Adjusted for 4% left and right margins */
  margin: 3% 4%;
  /* 3% top/bottom, 4% left/right */
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.title {
  height: 3%;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.presidential-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.presidential {
  display: flex;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  align-items: center;
  /* Center vertically within the parent */
  justify-content: center;
  /* Center horizontally within the child div */
  flex-grow: 1;
}

.map-statistics,
.map-content {
  height: 100%;
  box-sizing: border-box;
}

.map-statistics {
  width: 35%;
}

.map-content {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.map-controls {
  padding: 4px;
  box-sizing: border-box;
  flex-shrink: 0;
  /* Prevent shrinking */
  border: 1px solid #ccc;
}

.map-container {
  /* background-color: white; */
  flex-grow: 1;
  /* Make this div fill the available space */
  box-sizing: border-box;
}

.bottom-container {
  display: flex;
  flex-grow: 1;
  padding: 10px;
  margin: 0;
}

.left,
.right {
  flex: 1;
  background-color: #FFF;
  padding: 2px;
  box-sizing: border-box;
  /* text-align: center; */
}

.left {
  margin-right: 2px;
}

.right {
  margin-left: 2px;
}

.election-results {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.title {
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
  margin: 30px 0px;
  /* margin-top: 0; */
  color: #000;
  letter-spacing: 1px;
}

.results-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.candidates {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
}

.candidate {
  display: flex;
  align-items: center;
  width: 45%;
  min-width: 150px;
}

.candidate-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 13px;
}

.candidate-info {
  display: flex;
  flex-direction: column;
}

.electoral-votes {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.candidate-name {
  font-weight: bold;
  font-size: 1rem;
}

.vote-percentage,
.vote-count {
  font-size: 2.1rem;
  font-weight: bold;
}

.vote-percentage {
  margin-bottom: 15px;
  letter-spacing: 1px;
}


.vote-count {
  letter-spacing: 1px;
}

.results-section {
  width: 100%;
  margin-top: 20px;
}

.bar {
  height: 30px;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 30px;
}

.first-leading-party-bar,
.second-leading-party-bar,
.other-leading-party-bar {
  height: 100%;
  position: absolute;
}

.first-leading-party-bar {
  left: 0;
}

.second-leading-party-bar {
  right: 0;
}

.middle-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 3px;
  height: 100%;
  background-color: #000;
}

.divider-line {
  width: 8px;
  height: 100%;
  background-color: #EEE;
}

.pointer {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid #000;
}

.win-marker {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  white-space: nowrap;
  margin-top: 10px;
}

.win-margin {
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  margin-bottom: 30px;
}

.second-leading-party {
  flex-direction: row-reverse;
  text-align: right;
}

.others {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
}

.others .candidate-info {
  display: inline-block;
}
</style>
