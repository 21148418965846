import { ref, watch, computed } from 'vue'
import { defineStore } from 'pinia'
import L from 'leaflet'
import type { FeatureCollection } from 'geojson'
import {
  defaultRegionLayerStyle,
  defaultConstituencyLayerStyle,
  highlightLayerStyle,
  selectedLayerStyle,
  unknown
} from '@/modules/map/utils/map-layer-styles'
import { LayerLevel } from '@/modules/map/enums/layer-level.enum'

import type Region from '@/modules/data/models/region.model'
import type Constituency from '@/modules/data/models/constituency.model'

import { useDataStore } from '@/modules/data/stores/data.store'

import { trimAndLowercase } from '../../core/utils/string.util'
import partyInfo from '@/assets/election/party_info.json'
import { PredictionParty } from '../enums/prediction-party.enum'


export const usePredictionMapStore = defineStore('predictionMap', () => {
  const mDataStore = useDataStore()

  const mSelectedSixteenRegions = ref<Region[]>([])
  const mSelectedTenRegions = ref<Region[]>([])

  const mFilteredConstituencies = ref<Constituency[]>([])
  const mSelectedConstituencies = ref<Constituency[]>([])

  const mSelectedConstituenciesNpp = ref<Constituency[]>([])
  const mSelectedConstituenciesNdc = ref<Constituency[]>([])

  const mPredictionParties = ref<string[]>(Object.values(PredictionParty))
  const mSelectedPredictionParty = ref<string>("NPP")


  const mMap = ref<L.Map | null>(null)
  const mCurrentLevel = ref<LayerLevel | null>(LayerLevel.CONSTITUENCY)
  const mNationalBounds = ref<L.LatLngBounds | null | undefined>(null)

  function resetStore() {
    mSelectedPredictionParty.value = "NPP"
    mSelectedSixteenRegions.value = []
    mSelectedTenRegions.value = []

    mSelectedConstituencies.value = []
    mSelectedConstituenciesNpp.value = []
    mSelectedConstituenciesNdc.value = []

    mCurrentLevel.value = LayerLevel.REGION_SIXTEEN
    setActiveLayer(mCurrentLevel.value)
  }

  const layers = {
    sixteenRegions: null as L.GeoJSON | null,
    tenRegions: null as L.GeoJSON | null,
    constituency: null as L.GeoJSON | null
  }

  const defaultMapView = {
    center: [7.9465, -1.0232],
    zoom: 8
  }

  watch(mSelectedSixteenRegions, (selectedRegions) => {
    if (selectedRegions.length < 1 && (mCurrentLevel.value === LayerLevel.REGION_SIXTEEN_CONSTITUENCY)) {

      const activeLayer = LayerLevel.REGION_SIXTEEN
      setActiveLayer(activeLayer)
      mSelectedSixteenRegions.value = []
      mSelectedConstituencies.value = []
    }
    if (selectedRegions.length >= 1) {
      mCurrentLevel.value = LayerLevel.REGION_SIXTEEN_CONSTITUENCY;
      filterConstituencies()
      if (mMap.value && layers.constituency) {
        const bounds = layers.constituency.getBounds()
        mMap.value.fitBounds(bounds)
      }
      setActiveLayer(LayerLevel.REGION_SIXTEEN_CONSTITUENCY)
    }
  })


  watch(mSelectedTenRegions, (selectedRegions) => {
    if (selectedRegions.length < 1 && mCurrentLevel.value === LayerLevel.REGION_TEN_CONSTITUENCY) {

      setActiveLayer(LayerLevel.REGION_TEN)
      mSelectedSixteenRegions.value = []
      mSelectedConstituencies.value = []
    }
    if (selectedRegions.length >= 1) {
      mCurrentLevel.value = LayerLevel.REGION_TEN_CONSTITUENCY;
      filterConstituencies()
      if (mMap.value && layers.constituency) {
        const bounds = layers.constituency.getBounds()
        mMap.value.fitBounds(bounds)
      }
      setActiveLayer(LayerLevel.REGION_TEN_CONSTITUENCY)
    }
  })


  const constituenciesNppCount = () =>
    computed(() => mSelectedConstituenciesNpp.value.length)

  const constituenciesNdcCount = () =>
    computed(() => mSelectedConstituenciesNdc.value.length)

  function filterConstituencies() {
    if (!mDataStore.constituencyGeoJson) return;

    layers.constituency?.clearLayers();
    const isTenRegions = mCurrentLevel.value === LayerLevel.REGION_TEN_CONSTITUENCY;
    const selectedRegions = isTenRegions ? mSelectedTenRegions.value : mSelectedSixteenRegions.value;
    const regionProperty = isTenRegions ? 'ten_regions' : 'region';

    const constituencyGeoJsonData: FeatureCollection = {
      type: 'FeatureCollection',
      features: selectedRegions.length >= 1
        ? mDataStore.constituencyGeoJson.features.filter((feature) =>
          selectedRegions.some(
            (region) => trimAndLowercase(region.name) === trimAndLowercase(feature.properties?.[regionProperty])
          )
        )
        : mDataStore.constituencyGeoJson.features
    };

    mFilteredConstituencies.value = constituencyGeoJsonData.features.map((feature, index) => ({
      id: index + 1,
      name: feature.properties?.constituen,
      region: feature.properties?.region,
      regionOld: feature.properties?.region_old,
    }));

    layers.constituency?.addData(constituencyGeoJsonData as GeoJSON.GeoJsonObject);
  }


  function initializeMap(mapContainer: HTMLElement) {
    if (mapContainer) {
      mMap.value = L.map(mapContainer, {
        center: defaultMapView.center as L.LatLngExpression,
        zoom: defaultMapView.zoom,
        zoomAnimation: true,
        zoomSnap: 0.25,
        zoomDelta: 0.25,
        wheelDebounceTime: 40
      })
    }
  }

  function initializeLayers() {
    if (!mMap.value) return

    layers.sixteenRegions = L.geoJSON(mDataStore.sixteenRegionsGeoJson, {
      style: defaultRegionLayerStyle,
      onEachFeature: onEachRegion
    })

    layers.tenRegions = L.geoJSON(mDataStore.tenRegionsGeoJson, {
      style: defaultRegionLayerStyle,
      onEachFeature: onEachRegion
    })

    layers.constituency = L.geoJSON(mDataStore.constituencyGeoJson, {
      style: defaultConstituencyLayerStyle,
      onEachFeature: onEachConstituency
    })

    setActiveLayer(LayerLevel.CONSTITUENCY)
  }

  function setActiveLayer(level: LayerLevel) {
    if (!mMap.value) return;

    mCurrentLevel.value = level;

    // Remove all layers that are not applicable to the selected level
    if (layers.sixteenRegions && level !== LayerLevel.REGION_SIXTEEN) {
      mMap.value.removeLayer(layers.tenRegions as L.GeoJSON);
      mMap.value.removeLayer(layers.constituency as L.GeoJSON);
    }
    if (layers.tenRegions && level !== LayerLevel.REGION_TEN) {
      mMap.value.removeLayer(layers.sixteenRegions as L.GeoJSON);
      mMap.value.removeLayer(layers.constituency as L.GeoJSON);
    }
    if (layers.constituency && level !== LayerLevel.CONSTITUENCY
      && level !== LayerLevel.REGION_SIXTEEN_CONSTITUENCY
      && level !== LayerLevel.REGION_TEN_CONSTITUENCY) {
      mMap.value.removeLayer(layers.sixteenRegions as L.GeoJSON);
      mMap.value.removeLayer(layers.tenRegions as L.GeoJSON);
    }

    // Add the appropriate layer based on the selected level
    switch (level) {
      case LayerLevel.REGION_SIXTEEN:
        layers.sixteenRegions?.addTo(mMap.value as L.Map);
        break;
      case LayerLevel.REGION_TEN:
        layers.tenRegions?.addTo(mMap.value as L.Map);
        break;
      case LayerLevel.CONSTITUENCY:
      case LayerLevel.REGION_SIXTEEN_CONSTITUENCY:
      case LayerLevel.REGION_TEN_CONSTITUENCY:
        layers.constituency?.addTo(mMap.value as L.Map);
        break;
    }


    // Reset selections and apply filters when switching between region levels
    if (level === LayerLevel.REGION_SIXTEEN || level === LayerLevel.REGION_TEN) {

      // Adjust map bounds and zoom to the national bounds if they are not set
      if (!mNationalBounds.value) {
        mNationalBounds.value = (layers.sixteenRegions?.getBounds() || layers.tenRegions?.getBounds()) ?? null;
      }
      if (mNationalBounds.value) {
        mMap.value.fitBounds(mNationalBounds.value);
        // zoomToFeature(mNationalBounds.value);
      }
      mSelectedSixteenRegions.value = [];
      mSelectedConstituencies.value = [];
      filterConstituencies();
    }

    // updateLayers();
  }

  function getPartyColors(partyName: string | null) {
    if (!partyName) return {
      color: unknown,
      colorMedium: unknown,
      colorLow: unknown,
    }
    const party = partyInfo.find(
      (p) => trimAndLowercase(p.name) === trimAndLowercase(partyName)
    )

    return {
      color: party?.color ?? unknown,
      colorMedium: party?.colorMedium ?? unknown,
      colorLow: party?.colorLow ?? unknown,
    }
  }

  function getFillColor(area: string): string {
    return getPartyColors(mSelectedPredictionParty.value).color;
  }


  function updateLayers() {
    const updateLayerStyle = (
      layer: L.Layer,
      regionOrConstituency: string,
      isSelected: boolean,
      defaultWeight: number
    ) => {
      const layerS = layer as L.Path;
      const regionNameNormalized = trimAndLowercase(regionOrConstituency);
      const fillColor = getFillColor(regionNameNormalized);
      const weight = isSelected ? selectedLayerStyle.weight : defaultWeight;

      const currentStyle = layerS.options;
      layerS.setStyle({
        ...currentStyle,
        fillColor: fillColor,
        weight: weight,
      });
    };

    const updateRegionLayers = (layersToUpdate: L.LayerGroup | null, selectedRegions: any[], defaultWeight: number) => {
      if (layersToUpdate) {
        layersToUpdate.eachLayer((layer: L.Layer) => {
          const region = (layer as any).feature.properties.region;
          const isSelected = selectedRegions.some(
            (c) => trimAndLowercase(c.name) === trimAndLowercase(region)
          );
          updateLayerStyle(layer, region, isSelected, defaultWeight);
        });
      }
    };

    if (mCurrentLevel.value === LayerLevel.REGION_SIXTEEN) {
      updateRegionLayers(layers.sixteenRegions, mSelectedSixteenRegions.value, defaultRegionLayerStyle.weight);
    } else if (mCurrentLevel.value === LayerLevel.REGION_TEN) {
      updateRegionLayers(layers.tenRegions, mSelectedSixteenRegions.value, defaultRegionLayerStyle.weight);
    } else if (
      mCurrentLevel.value === LayerLevel.CONSTITUENCY ||
      mCurrentLevel.value === LayerLevel.REGION_SIXTEEN_CONSTITUENCY ||
      mCurrentLevel.value === LayerLevel.REGION_TEN_CONSTITUENCY
    ) {
      if (layers.constituency) {
        layers.constituency.eachLayer((layer: L.Layer) => {
          const layerS = layer as L.Path
          const constituencyName = (layer as any).feature.properties.constituen;
          const constituencyNameNormalized = trimAndLowercase(constituencyName)

          let fillColor: string | undefined = unknown
          let weight: number = defaultConstituencyLayerStyle.weight

          if (mSelectedConstituencies.value.length >= 1) {
            mSelectedConstituencies.value.forEach((c) => {
              if (trimAndLowercase(c.name) === constituencyNameNormalized) {
                fillColor = getFillColor(constituencyNameNormalized)
                weight = selectedLayerStyle.weight
              }
            })
          } else {
            fillColor = getFillColor(constituencyNameNormalized)
            weight = defaultConstituencyLayerStyle.weight
          }

          const currentStyle = layerS.options
          layerS.setStyle({
            ...currentStyle,
            fillColor: fillColor,
            weight: weight
          })
        });
      }
    }
  }

  const fitBounds = () => {
    if (mMap.value && mNationalBounds.value) {
      zoomToFeature(mNationalBounds.value)
    }
  }

  function zoomToFeature(bounds: L.LatLngBounds) {
    mMap.value?.flyToBounds(bounds, {
      duration: 0.5, // Duration of animation in seconds
      easeLinearity: 0.25 // Smooth out the animation
    })
  }

  function onEachRegion(feature: any, layer: L.Layer) {
    const regionName = feature.properties.region

    layer.on({
      click: () => {
        const isTenRegions = mCurrentLevel.value === LayerLevel.REGION_TEN;
        const regionNameNormalized = trimAndLowercase(regionName);

        // Choose the appropriate region data set based on the current level
        const regions = isTenRegions ? mDataStore.tenRegions : mDataStore.sixteenRegions;
        const selectedRegions = isTenRegions ? mSelectedTenRegions.value : mSelectedSixteenRegions.value;

        // Find the region that matches the normalized name
        const region = regions.find((r) => trimAndLowercase(r.name) === regionNameNormalized);

        if (region) {
          // Check if the region is already selected
          const regionAlreadySelected = selectedRegions.some(
            (r) => trimAndLowercase(r.name) === regionNameNormalized
          );

          // Add or remove the region from the selected list based on its current status
          const updatedRegions = regionAlreadySelected
            ? selectedRegions.filter((r) => trimAndLowercase(r.name) !== regionNameNormalized)
            : [...selectedRegions, region];

          // Update the appropriate selected regions variable based on the current level
          if (isTenRegions) {
            mSelectedTenRegions.value = updatedRegions;
          } else {
            mSelectedSixteenRegions.value = updatedRegions;
          }
        }

        // zoomToFeature(e.target.getBounds())
        // setActiveLayer(LayerLevel.CONSTITUENCY)
      },
      mousedown: (e: L.LeafletMouseEvent) => { },
      mouseup: (e: L.LeafletMouseEvent) => { },
      mouseover: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        const isTenRegions = mCurrentLevel.value === LayerLevel.REGION_TEN;
        const selectedRegions = isTenRegions ? mSelectedTenRegions.value : mSelectedSixteenRegions.value;

        // Proceed only if the region is not already selected
        if (!selectedRegions.includes(regionName)) {
          const currentStyle = layer.options;
          const newStyle = {
            ...currentStyle,
            weight: highlightLayerStyle.weight,
          };
          layer.setStyle(newStyle);

          layer.bindTooltip(
            `<h5>${regionName.toUpperCase()}</h5>`,
            { direction: 'top' }
          ).openTooltip();
        }

      },
      mouseout: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        const isTenRegions = mCurrentLevel.value === LayerLevel.REGION_TEN;
        const selectedRegions = isTenRegions ? mSelectedTenRegions.value : mSelectedSixteenRegions.value;
        if (!selectedRegions.includes(regionName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            weight: defaultRegionLayerStyle.weight
          }
          layer.setStyle(newStyle)
        }
      }
    })
  }

  function onEachConstituency(feature: any, layer: L.Layer) {
    const constituencyName = feature.properties.constituen.trim()

    layer.on({
      click: (e) => {
        const clickedLayer = e.target as L.Layer

        const constituencyNameNormalized = trimAndLowercase(constituencyName)
        const constituency = mDataStore.constituencies.find(
          (c) => trimAndLowercase(c.name) === constituencyNameNormalized
        )

        if (constituency) {
          const constituencyAlreadySelected = mSelectedConstituencies.value.some(
            (c) => trimAndLowercase(c.name) === constituencyNameNormalized
          )

          let fillColor = unknown

          if (!constituencyAlreadySelected) {
            mSelectedConstituencies.value = [...mSelectedConstituencies.value, constituency]
            mSelectedPredictionParty.value === PredictionParty.NPP
              ? mSelectedConstituenciesNpp.value = [...mSelectedConstituenciesNpp.value, constituency]
              : mSelectedConstituenciesNdc.value = [...mSelectedConstituenciesNdc.value, constituency]
            fillColor = getPartyColors(mSelectedPredictionParty.value).colorMedium

          } else {
            mSelectedConstituencies.value = mSelectedConstituencies.value.filter(
              (c) => trimAndLowercase(c.name) !== constituencyNameNormalized
            )
            mSelectedPredictionParty.value === PredictionParty.NPP
              ? mSelectedConstituenciesNpp.value = mSelectedConstituenciesNpp.value.filter(
                (c) => trimAndLowercase(c.name) !== constituencyNameNormalized
              )
              : mSelectedConstituenciesNdc.value = mSelectedConstituenciesNdc.value.filter(
                (c) => trimAndLowercase(c.name) !== constituencyNameNormalized
              )
            fillColor = defaultConstituencyLayerStyle.fillColor

          }

          if (clickedLayer instanceof L.Path) {
            const currentStyle = clickedLayer.options
            const newStyle = {
              ...currentStyle,
              weight: selectedLayerStyle.weight,
              fillColor: fillColor,
              fillOpacity: selectedLayerStyle.fillOpacity
            }
            clickedLayer.setStyle(newStyle)
          }
        }
      },
      mouseover: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        if (!mSelectedConstituencies.value.includes(constituencyName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            // weight: highlightLayerStyle.weight,
            fillOpacity: highlightLayerStyle.fillOpacity
          }
          layer.setStyle(newStyle)
        }
        layer.bindTooltip(
          `<h5>${constituencyName.toUpperCase()}</h5>`,
          { direction: 'top' }
        ).openTooltip()
      },
      mouseout: (e: L.LeafletMouseEvent) => {
        const layer = e.target as L.Path
        if (!mSelectedConstituencies.value.includes(constituencyName)) {
          const currentStyle = layer.options
          const newStyle = {
            ...currentStyle,
            // weight: defaultConstituencyLayerStyle.weight,
            fillOpacity: defaultConstituencyLayerStyle.fillOpacity
          }
          layer.setStyle(newStyle)
        }
      }
    })
  }


  return {
    currentLevel: mCurrentLevel,
    selectedSixteenRegions: mSelectedSixteenRegions,
    selectedTenRegions: mSelectedTenRegions,
    filteredConstituencies: mFilteredConstituencies,
    selectedConstituencies: mSelectedConstituencies,
    predictionParties: mPredictionParties,
    selectedPredictionParty: mSelectedPredictionParty,
    constituenciesNdcCount,
    constituenciesNppCount,
    fitBounds,
    initializeMap,
    initializeLayers,
    setActiveLayer,
    updateLayers,
    resetStore
  }
})
